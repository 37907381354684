import {useState} from 'react';
import {authenticationClient} from '../clients/clients';
import {AuthenticationResult} from '../types/mediaport/auth';
import {setAxiosSpecs} from '../utils/axios';

export default function useLogin() {

    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL!;
    const serviceApiKey = process.env.REACT_APP_API_SERVICE_APIKEY!;
    const serviceSecret = process.env.REACT_APP_API_SERVICE_SECRET!;

    const [apiKey, setApiKey] = useState(localStorage.getItem('apiKey'));
    const [secret, setSecret] = useState(localStorage.getItem('secret'));
    const [authenticated, setAuthenticated] = useState<boolean>(apiKey !== undefined && apiKey !== null);

    if (apiKey && secret) {
        setAxiosSpecs(apiBaseUrl, apiKey, secret);
    }

    const login = (username: string, password: string): Promise<AuthenticationResult> => {

        setAxiosSpecs(
            apiBaseUrl,
            serviceApiKey,
            serviceSecret
        );

        return authenticationClient
            .authenticate(username, password)
            .then(authenticationResult => {

                const apiKey = authenticationResult.ApiKey;
                const secret = authenticationResult.Secret;

                setApiKey(apiKey);
                setSecret(secret);

                localStorage.setItem('apiKey', apiKey);
                localStorage.setItem('secret', secret);

                setAuthenticated(true);

                return authenticationResult;
            });
    };

    const logout = () => {

        setAuthenticated(false);
        setApiKey(null);
        setSecret(null);

        localStorage.removeItem('apiKey');
        localStorage.removeItem('secret');
    };

    return {authenticated, apiKey, secret, apiBaseUrl, login, logout};
}
