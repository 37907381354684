import axios from 'axios';
import * as React from 'react';
import {useEffect, useState} from 'react';
import {fileClient} from '../clients/clients';
import {MPObject} from '../types/mediaport/mpobject';

export default function useLoadMPObject(controller: string, id: string, loadOptions: any) {

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [mpObject, setMPObject] = React.useState<MPObject>();

    useEffect(() => {
        console.debug('Reset MPObject');
        setMPObject(undefined);
        setError(false);
        setLoading(true);
    }, [controller, id, loadOptions]);

    useEffect(() => {

        console.debug('Load MPObject');

        setError(false);
        setLoading(true);

        if (!id) {
            return;
        }

        let cancel;

        fileClient.load(
            id,
            loadOptions,
            {
                cancelToken: new axios.CancelToken(c => cancel = c)
            }
        ).then(mpObject => {
            setMPObject(mpObject);
            setLoading(false);
        }).catch(e => {
            if (axios.isCancel(e)) {
                return;
            }
            setError(true);
        });
        return () => cancel();
    }, [controller, id, loadOptions]);

    return {mpObject, loading, error};
}
