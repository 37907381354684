import {Delete} from '@mui/icons-material';
import {Alert, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Grid, List, ListItem, useMediaQuery, useTheme} from '@mui/material';
import {DialogProps} from '@mui/material/Dialog/Dialog';
import {useContext, useEffect, useState} from 'react';
import AppContext from '../../../AppContext';
import {collectionClient, downloadOptionTypeClient, fileClient} from '../../../clients/clients';
import DownloadFileRow from '../../../components/common/DownloadFileRow';
import {CollectionResource} from '../../../types/mediaport/collection';
import {MPObject} from '../../../types/mediaport/mpobject';
import {getAxiosInstance} from '../../../utils/axios';
import {toNumber} from '../../../utils/NumberUtils';
import {getLocalizableString} from '../../../utils/ResourceUtils';

interface Props extends DialogProps {
    collectionId?: number;
    onCancel: () => void;
}

interface DownloadOptionType {
    id: number,
    name: string,
    translation: string
}

export interface FileToSelectedDownloadOptionType {
    [key: number]: number;
}

function PinboardContentDialog(props: Props) {

    const context = useContext(AppContext);

    const [files, setFiles] = useState<MPObject[]>();
    const [active, setActive] = useState<MPObject[]>();
    const [downloadOptionTypes, setDownloadOptionTypes] = useState<Map<number, DownloadOptionType>>();
    const [fileToDownloadOptionTypes, setFileToDownloadOptionTypes] = useState<Map<number, DownloadOptionType[]>>();
    const [fileToSelectedDownloadOptionType, setFileToSelectedDownloadOptionType] = useState<FileToSelectedDownloadOptionType>({});
    const [errorDialogOpen, setErrorDialogOpen] = useState(false);

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const {collectionId, open, onCancel, ...dialogProps} = props;

    const setSelectedDownloadOptionType = (fileId: number, downloadOptionTypeId: number) => {
        setFileToSelectedDownloadOptionType({
            ...fileToSelectedDownloadOptionType,
            [fileId]: downloadOptionTypeId
        });
    };

    // Get all DownloadOptionTypes once
    useEffect(() => {
        console.debug('Get all DownloadOptionTypes.');
        downloadOptionTypeClient
            .getDownloadOptionTypes()
            .then(downloadOptionTypes => {
                setDownloadOptionTypes(
                    new Map(
                        downloadOptionTypes
                            .map((dot) => {
                                return {
                                    id: dot.id,
                                    name: dot.name,
                                    translation: getLocalizableString(dot, 'name', 'de'),
                                } as DownloadOptionType;
                            })
                            .filter(dot => dot.name !== 'user-defined')
                            .filter(dot => dot.translation)
                            .map(dot => [dot.id, dot] as [number, DownloadOptionType])
                    )
                );
            })
            .catch(e => {
                console.error('Failed to get all DownloadOptionTypes.', e);
            });
    }, []);

    // Get files of Collection
    useEffect(() => {

        if (collectionId === undefined) {
            setFiles(undefined);
            setActive(undefined);
            setFileToDownloadOptionTypes(undefined);
            return;
        }

        console.debug('Get Files of Collection.');

        fileClient
            .searchByQuery(
                {
                    'search': {
                        'query': {
                            'collection': {
                                'is': collectionId
                            }
                        },
                    },
                    'properties': {
                        'attributes': ['file.name', 'file.widthPixel', 'file.heightPixel']
                    }
                },
                {'params': {'page': 0, 'size': 100}}
            )
            .then(result => {
                setFiles(result.content);
                setActive(result.content);
            })
            .catch(e => {
                console.error('Failed to get Files of Collection.', e);
            });
    }, [collectionId]);

    // Get DownloadOptionTypes for Files (after files and downloadOptionTypes are loaded)
    useEffect(() => {

        if (collectionId === undefined || files === undefined || downloadOptionTypes === undefined) {
            return;
        }

        console.debug('Get DownloadOptionTypes for Files.');

        const fileIds = files.map(file => file.id);

        downloadOptionTypeClient.getDownloadOptionTypesForFiles(fileIds)
            .then(result => {

                // build mapping from File to possible DownloadOptionTypes
                const fileToDownloadOptionTypes = new Map(
                    result
                        .map(resource => [
                            resource.fileId,
                            resource.downloadOptionTypeIds
                                .map(id => downloadOptionTypes.get(id))
                                .filter(dot => dot)
                        ] as [number, DownloadOptionType[]])
                );
                setFileToDownloadOptionTypes(fileToDownloadOptionTypes);

                // build mapping from File to default selected DownloadOptionType
                const fileToSelectedDownloadOptionType = new Map(
                    Array.from(fileToDownloadOptionTypes)
                        .map(([fileId, downloadOptionTypes]) => [fileId, downloadOptionTypes[0].id])
                );
                setFileToSelectedDownloadOptionType({...Object.fromEntries(fileToSelectedDownloadOptionType.entries())});
            })
            .catch(e => {
                console.error('Failed to get DownloadOptionTypes for Files.', e);
            });

    }, [collectionId, files, downloadOptionTypes]);

    const startDownload = () => {

        const collection: CollectionResource = context.collections?.filter(c => c.id === collectionId)[0]!;
        const collectionName = getLocalizableString(collection, 'name', 'de');

        getAxiosInstance()
            .post(
                '/api/fileDownloads/create',
                {
                    name: 'Pinnwand ' + collectionName,
                    comment: 'Galeria Karstadt Kaufhof Download',
                    validityPeriod: 30,
                    files: active?.map(file => {
                        return {
                            id: toNumber(file.id),
                            downloadOptionTypeId: fileToSelectedDownloadOptionType[file.id]
                        };
                    })
                }
            )
            .then(result => {
                onCancel();
                context.updateDownloads();
                context.showSnackbar('Ihr Download wird vorbereitet.');
                console.log(result);
            })
            .catch(e => {
                console.error('Failed to start download.', e);
            });
    };

    const handleErrorDialogCancel = () => {
        setErrorDialogOpen(false);
    };

    const handleErrorDialogOk = () => {
        setErrorDialogOpen(false);
        deleteCollection();
    };

    const onDeleteCollection = () => {
        setErrorDialogOpen(true);
    };

    const deleteCollection = () => {
        if (collectionId) {
            collectionClient.delete(collectionId).then(() => {
                onCancel();
                context.showSnackbar('Pinnwand ' + collectionName + ' wurde gelöscht.');
                context.updateCollections();
            });
        }
    };

    const removeFileFromCollection = (fileId: string) => {
        if (collectionId) {
            collectionClient.removeMPObjectFromCollection(fileId, collectionId + '').then(() => {
                setFiles(files?.filter(f => f.id !== fileId));
            });
        }
    };

    if (collectionId === undefined || files === undefined || active === undefined) {
        return null;
    }

    const collectionName = context.collections
        ?.filter(c => c.id === collectionId)
        .map(collection => getLocalizableString(collection, 'name', 'de'))
        .pop() ?? '' + collectionId;

    const setActiveForFile = (mpObject: MPObject, isActive: boolean) => {
        if (isActive && !active.includes(mpObject)) {
            const newActive = active.map(v => v);
            newActive.push(mpObject);
            setActive(newActive);
            return;
        }

        if (!isActive && active.includes(mpObject)) {
            setActive(active.filter(f => f !== mpObject));
            return;
        }
    };

    const overlayButton = (mpObject: MPObject) => <Button
        variant="contained"
        sx={{marginRight: 'auto'}}
        startIcon={<Delete/>}
        fullWidth={true}
        onClick={() => removeFileFromCollection(mpObject.id)}
    >
        Bild entfernen
    </Button>;

    const additionalColumn = (mpObject: MPObject) => <Grid container item md={1} alignItems="center">
        <Grid item sm={1}>
            <Checkbox checked={active?.includes(mpObject)}
                      onChange={e => setActiveForFile(mpObject, e.target.checked)}
            />
        </Grid>
    </Grid>;

    return (
        <>
            {errorDialogOpen && (
                <Dialog open={errorDialogOpen} onClose={() => handleErrorDialogCancel()} fullScreen={fullScreen}>
                    <DialogTitle id="form-dialog-title">Hinweis</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            <Alert severity="error">Wollen sie die Pinnwand "{collectionName}" wirklich löschen?</Alert>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="text" onClick={() => handleErrorDialogCancel()}>
                            Abbrechen
                        </Button>
                        <Button color="secondary" variant="contained" onClick={() => handleErrorDialogOk()}>
                            Löschen
                        </Button>
                    </DialogActions>
                </Dialog>
            )}

            <Dialog {...dialogProps} open={open} onClose={() => onCancel()} maxWidth={'md'} fullScreen={fullScreen}>
                <DialogTitle id="form-dialog-title" sx={{pl: 2}}>Pinnwand: {collectionName}</DialogTitle>
                <DialogContent sx={{pl: 0, pr: 0}}>
                    {files?.length < 1 ? (
                        <DialogContentText sx={{padding: '8px 24px'}}>
                            Es befinden sich keine Bilder in dieser Pinnwand.
                        </DialogContentText>
                    ) : (
                        <List>
                            {files
                                ?.map((mpObject) => (
                                    <>
                                        <ListItem sx={{marginTop: 2, marginBottom: 2}}>
                                            <DownloadFileRow mpObject={mpObject}
                                                             fileDownloadOptionTypes={fileToDownloadOptionTypes && fileToDownloadOptionTypes.get(toNumber(mpObject.id)!)!}
                                                             selectedDownloadOptionType={fileToSelectedDownloadOptionType[mpObject.id]}
                                                             setSelectedDownloadOptionType={(dotId) => setSelectedDownloadOptionType(toNumber(mpObject.id)!, dotId)}
                                                             active={active?.includes(mpObject)}
                                                             overlayButton={overlayButton}
                                                             additionalColumn={additionalColumn}/>
                                        </ListItem>
                                        <Divider/>
                                    </>
                                ))
                            }
                        </List>)}
                </DialogContent>
                <DialogActions sx={{flexWrap: 'wrap'}}>

                    <Button
                        color="secondary"
                        sx={{marginRight: 'auto'}}
                        startIcon={<Delete/>}
                        onClick={() => onDeleteCollection()}
                    >
                        Pinnwand löschen
                    </Button>

                    <Button onClick={() => onCancel()}>
                        Schließen
                    </Button>

                    {files?.length > 0 && (
                        <Button onClick={() => startDownload()} color="primary" variant="contained" disabled={active.length === 0}>
                            Download starten
                        </Button>
                    )}

                </DialogActions>
            </Dialog>
        </>
    );
}

export default PinboardContentDialog;
