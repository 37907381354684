import React from 'react';
import {CollectionResource} from './types/mediaport/collection';
import {DownloadResource} from './types/mediaport/download';

export class AppConfig {

    apiKey: string | null = null;
    secret: string | null = null;

    apiBaseUrl: string = '';

    showSnackbar: (text: string) => void = () => {};

    downloads: DownloadResource[] = [];
    updateDownloads: () => void = () => {};

    collections?: CollectionResource[];
    updateCollections: () => void = () => {};

    defaultCollectionId?: string = '';
    updateDefaultCollectionId: (collectionId: string) => void = () => {};

    ftpUploadUrl?: string = undefined;
}

const AppContext = React.createContext<AppConfig>(new AppConfig());

export default AppContext;
