import {AxiosInstance} from 'axios';

export class GalleryClient {

    protected readonly axiosInstance: AxiosInstance;

    constructor(axiosInstance: AxiosInstance) {
        this.axiosInstance = axiosInstance;
    }

    public getFtpUploadUrl(): Promise<string> {
        return this.axiosInstance
            .get('/api/gallery/ftpUploadUrl')
            .then(response => {
                return response.data as string;
            });
    }
}
