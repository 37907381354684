import {Box, CircularProgress, LinearProgress, styled, Typography,} from '@mui/material';
import {grey} from '@mui/material/colors';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import useSearchMPObjects from '../../hooks/useSearchMPObjects';
import {getAttributeValue, getPreviewID} from '../../utils/MediaPortUtils';
import MPImage from '../common/MPImage';
import MPImageOverlay from '../common/MPImageOverlay';

interface ImageResultProps {
    search?: any,
}

const StyledMPImage = styled(MPImage)(({theme}) => ({
    maxHeight: '300px',
    objectFit: 'cover',
    maxWidth: '100%',
    minWidth: '100%',
}));

export default function ImageResult(props: ImageResultProps) {

    const {search} = props;

    const {
        items,
        totalItemCount,
        pageNumber,
        loading,
        hasMoreItems,
        loadMore,
        error
    } = useSearchMPObjects('files', search, 25);

    const [infiniteRef] = useInfiniteScroll({
        loading,
        hasNextPage: hasMoreItems,
        onLoadMore: loadMore,
        disabled: error,
        delayInMs: 0,
    });

    if (totalItemCount === 0) {
        return <Typography variant="h5" component="h5" sx={{
            paddingTop: 2,
            paddingLeft: 2,
            width: '100%',
        }}>Keine Suchergebnisse</Typography>;
    }

    return (
        <>
            {loading && <LinearProgress/>}

            {totalItemCount && <Typography variant="h5" component="h5" sx={{
                paddingTop: 2,
                paddingLeft: 2,
                width: '100%',
            }}>{totalItemCount} Suchergebnisse</Typography>}

            <Box sx={{
                listStyle: 'none',
                position: 'relative',
                display: 'flex',
                overflow: 'hidden',
                flexWrap: 'wrap',
                maxWidth: '100%',
                padding: 2,
                margin: 0
            }}>

                {items
                    .map((mpObject) => {

                        const workflowElementNames = mpObject.workflows.find(w => w.name === 'file.licenseWorkflow')?.workflowElements.map(we => we.name);

                        let bannerText;
                        if (workflowElementNames?.includes('toCheck')) {
                            bannerText = 'Zu prüfen';
                        }
                        else if (workflowElementNames?.includes('renewalRequested')) {
                            bannerText = 'Lizenzänderung beantragt';
                        }
                        else if (workflowElementNames?.includes('licenseRenewalNotNeeded')) {
                            bannerText = 'Lizenzverlängerung nicht benötigt';
                        }
                        else if (workflowElementNames?.includes('invalidLicense')) {
                            bannerText = 'Keine gültige Lizenz';
                        }

                        const validLicense = !bannerText;

                        return <Box component={'div'} key={mpObject.id} sx={{
                            position: 'relative',
                            flexGrow: 1,
                            display: 'flex',
                            padding: '4px',
                        }}>
                            {
                                !validLicense && (
                                    <Box sx={{
                                        position: 'absolute',
                                        top: 12, left: 12, bottom: 12, right: 12,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center'
                                    }}>
                                        <Box sx={{
                                            borderColor: grey[800],
                                            borderRadius: '4px',
                                            width: '100%',
                                            opacity: 0.9,
                                            padding: 1,
                                            fontWeight: 'bold',
                                            borderStyle: 'solid',
                                            borderWidth: 'thin',
                                            color: 'white',
                                            backgroundColor: 'rgba(185, 74, 72, .8)',
                                            textOverflow: 'ellipsis',
                                            overflow: 'hidden',
                                            textAlign: 'center'
                                        }}>{bannerText}</Box>
                                    </Box>
                                )
                            }

                            <StyledMPImage
                                previewId={getPreviewID(mpObject)}
                                fileWidthPixel={getAttributeValue(mpObject, 'file.widthPixel')}
                                fileHeightPixel={getAttributeValue(mpObject, 'file.heightPixel')}
                            />

                            <MPImageOverlay
                                fileId={mpObject.id}
                                previewId={getPreviewID(mpObject)}
                                fileName={getAttributeValue(mpObject, 'file.name')}
                                fileWidthPixel={getAttributeValue(mpObject, 'file.widthPixel')}
                                fileHeightPixel={getAttributeValue(mpObject, 'file.heightPixel')}
                                showDownloadButton={validLicense}
                            />
                        </Box>;
                    })}

                <Box component={'div'} sx={{
                    flexGrow: 9999,
                }}/>

                {hasMoreItems && <div ref={infiniteRef}/>}

                {loading && pageNumber !== 0 && (
                    <Box sx={{
                        width: '100%',
                        padding: 2,
                        flexGrow: 1,
                        position: 'relative',
                        textAlign: 'center'
                    }}>
                        <CircularProgress/>
                    </Box>
                )}
            </Box>

            {!loading && !hasMoreItems && (
                <Typography variant="h5" component="h5" sx={{
                    paddingBottom: 2,
                    paddingLeft: 2,
                    width: '100%'
                }}>Keine weiteren Ergebnisse</Typography>
            )}
        </>
    );
}
