import {ExitToApp, OpenInNew, PinDrop} from '@mui/icons-material';
import {Box, Button, Grid, Hidden, Paper, styled} from '@mui/material';
import React from 'react';
import {useHistory} from 'react-router-dom';
import AppContext from '../../AppContext';
import {customThemeSettings} from '../../utils/theme';
import DownloadButton from '../gallery/download/DownloadButton';

const ResponsiveButton = styled(Button)(({theme}) => ({
    margin: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
        '& .MuiButton-startIcon, & .MuiButton-endIcon': {
            margin: 0,
        }
    }
}));

interface HeaderProps {
    showOverviewButton: boolean,
    showPinboardButton: boolean,
    showDownloadButton: boolean,
    onTogglePinboard?: () => void,
}

export default function Header(props: HeaderProps) {

    const context = React.useContext(AppContext);
    const history = useHistory();

    return (

        <header>
            <Box sx={{
                backgroundColor: customThemeSettings.mainColor,
                height: theme => theme.spacing(1),
            }}/>
            <Paper>
                <Box display="flex" flexDirection="row">
                    <Grid container justifyContent="space-between" alignItems="center" textAlign="initial" p={1}>
                        {/* LOGO */}
                        <Grid item order={{md: 2}} xs="auto" md={4} display="flex" justifyContent="center">
                            <Box component={'svg'}
                                 order={{sm: 2}}
                                 m={1}
                                 viewBox="0 0 1207.77 255.12" sx={{
                                fill: customThemeSettings.mainColor,
                                height: '40px',
                            }}>
                                <use href="#logo"/>
                            </Box>
                        </Grid>

                        {/* LOGOUT-BUTTON */}
                        <Grid item order={{md: 4}} xs="auto">
                            <ResponsiveButton variant="outlined"
                                              onClick={() => history.push('/logout')}
                                              startIcon={<ExitToApp/>}>
                                <Hidden mdDown>Logout</Hidden>
                            </ResponsiveButton>
                        </Grid>

                        {/* COL-BREAKER */}
                        <Grid item xs={12} m={-1}/>
                        <Grid item order={{md: 1}} md={4}>
                            {/* UPLOAD-BUTTON */
                                props.showOverviewButton &&
                                <ResponsiveButton aria-controls="menu"
                                                  aria-haspopup="true"
                                                  onClick={() => window.open(context.ftpUploadUrl, '_blank')}
                                                  variant="outlined"
                                                  endIcon={<OpenInNew/>}>
                                    <Hidden mdDown>Upload</Hidden>
                                </ResponsiveButton>
                            }
                        </Grid>
                        {/* OTHER BUTTONS */}
                        <Grid item order={{md: 3}} md="auto" ml="auto">
                            {
                                props.showDownloadButton &&
                                <DownloadButton/>
                            }
                            {
                                props.showPinboardButton &&
                                <ResponsiveButton startIcon={<PinDrop/>} onClick={props.onTogglePinboard}>
                                    <Hidden mdDown>Pinnwand</Hidden>
                                </ResponsiveButton>
                            }
                        </Grid>
                    </Grid>
                </Box>
            </Paper>
        </header>
    );
}
