import {AxiosInstance} from 'axios';
import {EnumerationResource, EnumerationValueResource} from '../types/mediaport/enumeration';

export class EnumerationClient {

    protected readonly axiosInstance: AxiosInstance;

    constructor(axiosInstance: AxiosInstance) {
        this.axiosInstance = axiosInstance;
    }

    public searchByName(enumerationName: string): Promise<EnumerationResource> {
        return this.axiosInstance
            .get('/api/enumerations/search/byName',
                {'params': {'name': enumerationName}}
            )
            .then(result => {
                return result.data;
            });
    }

    public searchValue(enumeration: string, value: string): Promise<EnumerationValueResource> {
        return this.axiosInstance
            .get('/api/enumerationValues/search/byEnumerationAndValue',
                {
                    'params': {
                        'enumeration': enumeration,
                        'value': value
                    }
                }
            )
            .then(result => {
                return result.data;
            });
    }
}
