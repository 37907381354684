import {Box, FormControlLabel, Grid, Radio, RadioGroup, Tooltip} from '@mui/material';
import React, {useMemo} from 'react';
import {MPObject} from '../../types/mediaport/mpobject';
import {getAttributeValue, getPreviewID} from '../../utils/MediaPortUtils';
import {toNumber} from '../../utils/NumberUtils';
import MPImage from './MPImage';

interface DownloadOptionType {
    id: number,
    name: string,
    translation: string
}

interface DownloadFileRowProps {
    mpObject: MPObject,
    overlayButton?: (mpObject: MPObject) => JSX.Element, //Should return <Button ...>...</Button>
    fileDownloadOptionTypes?: DownloadOptionType[],
    selectedDownloadOptionType?: number,
    setSelectedDownloadOptionType: (dotId: number) => void,
    active: boolean,
    additionalColumn?: (mpObject: MPObject) => JSX.Element //Should return <Grid item ...>...</Grid>
}

function DownloadFileRow(props: DownloadFileRowProps) {

    const {mpObject, overlayButton, fileDownloadOptionTypes, selectedDownloadOptionType, setSelectedDownloadOptionType, active, additionalColumn} = props;

    const attributes = useMemo(() => {
        return [
            'file.name',
            'file.widthPixel',
            'file.heightPixel'
        ];
    }, []);

    return <Grid container spacing={2}>
        {additionalColumn && additionalColumn(mpObject)}
        <Grid item sm={4} md={4}>
            <Box sx={{
                position: 'relative',
                '& > button': {
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    right: 0,
                    display: 'flex',
                },
                '&:not(:hover) > button': {
                    display: 'none',
                }
            }}>
                <MPImage previewId={getPreviewID(mpObject)} style={{width: '100%'}}/>
                {overlayButton && overlayButton(mpObject)}
            </Box>
        </Grid>
        <Grid item sm={4} md={4}>
            {attributes
                .map(attributeName => mpObject.attributes.filter(attribute => attribute.name === attributeName)[0])
                ?.map((mpAttribute, index) => (
                    mpAttribute && <span key={index}>{convertAttributes(mpAttribute.name, getAttributeValue(mpObject, mpAttribute.name))}</span>
                ))
            }
        </Grid>
        <Grid item sm={3} md={3}>
            {fileDownloadOptionTypes && fileDownloadOptionTypes?.length > 0 && (
                <DownloadOptionTypeSelection
                    downloadOptionTypes={fileDownloadOptionTypes}
                    selectedDownloadOptionType={selectedDownloadOptionType ?? -1}
                    setSelectedDownloadOptionType={setSelectedDownloadOptionType}
                    enabled={active}/>
            )}
        </Grid>
    </Grid>;
}

interface DownloadOptionTypeSelectionProps {
    downloadOptionTypes: DownloadOptionType[];
    selectedDownloadOptionType: number,
    setSelectedDownloadOptionType: (dotId: number) => void,
    enabled: boolean,
}

function DownloadOptionTypeSelection(props: DownloadOptionTypeSelectionProps) {

    const {downloadOptionTypes, selectedDownloadOptionType, setSelectedDownloadOptionType, enabled} = props;

    if (downloadOptionTypes === undefined || setSelectedDownloadOptionType === undefined) {
        return null;
    }

    return <RadioGroup
        value={selectedDownloadOptionType}
        sx={{whiteSpace: 'nowrap'}}
        onChange={(event, value) => setSelectedDownloadOptionType(toNumber(value)!)}>
        {downloadOptionTypes.map(dot =>
            <FormControlLabel style={{fontSize: '1em'}}
                              value={dot.id} control={<Radio color="primary" disabled={!enabled}/>}
                              label={dot.translation}/>
        )}
    </RadioGroup>;
}

function convertAttributes(keyObject: string, val: any) {
    const key = keyObject.split('.').pop();
    switch (key) {
        case 'name':
            const label = <div><b>{addBreaks(shorten(val))}</b></div>;

            return val.length <= 30
                ? label
                : <Tooltip title={val} enterDelay={500} arrow>{label}</Tooltip>;
        case 'widthPixel':
            return <span>{val} x</span>;
        case 'heightPixel':
            return <span> {val} Pixel</span>;
        default:
            return;
    }
}

function shorten(text: string) {
    if (text.length <= 30) {
        return text;
    }

    return text.substr(0, 14) + '...' + text.substr(text.length - 13, 13);
}

function addBreaks(text: string) {
    if (text.length === 0) {
        return text;
    }

    var result = text.substr(0, 1);
    var i;
    for (i = 1; i < text.length; i++) {
        result += '\u200B' + text.substr(i, 1);
    }

    return result;
}

export default DownloadFileRow;