import {Error, GetApp} from '@mui/icons-material';
import {Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText, useMediaQuery, useTheme} from '@mui/material';

import {DialogProps} from '@mui/material/Dialog/Dialog';

import {formatDistanceToNow, parseISO} from 'date-fns';
import {de} from 'date-fns/locale';
import {useContext} from 'react';
import AppContext from '../../../AppContext';
import {DownloadResource} from '../../../types/mediaport/download';
import LoadingIndicator from '../../common/LoadingIndicator';

interface Props extends DialogProps {
    downloads: DownloadResource[],
    onCancel: () => void;
}

function DownloadDialog(props: Props) {

    const appContext = useContext(AppContext);

    const {downloads, onCancel} = props;

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const formatDuration = (download: string): string => {
        return formatDistanceToNow(
            parseISO(download),
            {
                locale: de,
                addSuffix: true
            }
        );
    };

    return (
        <Dialog {...props} onClose={() => onCancel()} fullScreen={fullScreen}>
            <DialogTitle sx={{paddingLeft: 2}}>Downloads</DialogTitle>
            <DialogContent>
                {downloads.length < 1 && (
                    <DialogContentText>
                        Hier befinden sich die Downloads der Pinnwand
                    </DialogContentText>)}
                <List dense={true} sx={{marginLeft: -3, marginRight: -3}}>
                    {downloads
                        ?.map((download) => {
                            if (download.state === 'FINISHED') {
                                return (
                                    <ListItem key={download.id} sx={{paddingRight: 8}}>
                                        <ListItemText
                                            primary={download.name}
                                            secondary={formatDuration(download.creationDate)}
                                        />
                                        <ListItemSecondaryAction>
                                            <Box color="success.main">
                                                <IconButton
                                                    component={'a'}
                                                    href={`${appContext.apiBaseUrl}/api/public/karstadt/downloads/${download.token}/start?apiKey=${appContext.apiKey}`}
                                                    onClick={() => onCancel()}
                                                    color="inherit"
                                                    download
                                                >
                                                    <GetApp/>
                                                </IconButton>
                                            </Box>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                );
                            }
                            return (
                                <ListItem key={download.id}>
                                    <ListItemText
                                        primary={download.name}
                                        secondary={formatDuration(download.creationDate)}
                                    />
                                    <IconButton disabled aria-label="download" size="large">
                                        {download.state === 'FAILED' ? <Error color={'error'}/> :
                                            <LoadingIndicator/>}
                                    </IconButton>
                                </ListItem>
                            );
                        })}
                </List>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => onCancel()}>
                    Schließen
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default DownloadDialog;
