import {useEffect, useState} from 'react';
import {downloadClient} from '../clients/clients';
import {DownloadResource} from '../types/mediaport/download';

export default function useDownloads() {

    const [downloads, setDownloads] = useState<DownloadResource[]>([]);

    useEffect(() => {
        updateDownloads(); // initial fetch
        const interval = setInterval(() => updateDownloads(), 15_000); // fetch every 30 seconds
        return () => clearInterval(interval); // cleanup function
    }, []);

    const updateDownloads = () => {

        console.debug('Fetch Downloads.');

        downloadClient
            .getLatestDownloads()
            .then(downloads => {
                setDownloads(downloads);
            })
            .catch(e => {
                console.error('Failed to load downloads.', e);
            });
    };

    return {downloads, updateDownloads};
}