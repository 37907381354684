import {FilterList} from '@mui/icons-material';
import {Box, Button, FormControl, Grid, MenuItem, Select} from '@mui/material';
import {useState} from 'react';
import useLoadEnumeration from '../../hooks/useLoadEnumeration';
import {LocalizableResource} from '../../types/mediaport/localization';
import {getLocalizableString} from '../../utils/ResourceUtils';

interface Props {

    activeFilter: string;
    onActiveFilterChange: (activeFilter: string) => void;

    toggleSidebar: () => void;
}

function FilterBar(props: Props) {

    const enumeration = useLoadEnumeration('file.classificationFilter');

    const [activeFilter, setActiveFilter] = useState(props.activeFilter);

    return (
        <Box p={2}>
            <Grid container spacing={2} justifyContent="space-between">
                <Grid item>
                    <Button
                        variant={'outlined'}
                        startIcon={<FilterList/>}
                        onClick={() => props.toggleSidebar()}
                    >Filter</Button>
                </Grid>
                <Grid item>
                    {/* custom spacing due to safari flexbox bug: https://github.com/mui-org/material-ui/issues/17142 */}
                    <Box sx={{
                        mx: -1
                    }}>
                        <FormControl variant="standard" sx={{m: 1, minWidth: 120}}>
                            <Select
                                //labelId="label-classficiationFilter"
                                id="select-classificationFilter"
                                value={activeFilter}
                                label="Alle"
                                onChange={e => {
                                    e.preventDefault();
                                    setActiveFilter(e.target.value);
                                    props.onActiveFilterChange(e.target.value);
                                }}
                            >
                                <MenuItem key="classFilter__all_" value="_all_">Alle</MenuItem>
                                {enumeration?.enumerationValues
                                    .map(enumValue => {
                                        return <MenuItem key={'classFilter_' + enumValue.value} value={enumValue.value}>
                                            {getLocalizableString(enumValue as LocalizableResource, 'name', 'de')}
                                        </MenuItem>;
                                    })
                                }
                            </Select>
                        </FormControl>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
}

export default FilterBar;
