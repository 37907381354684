import {Button, ButtonGroup, Radio} from '@mui/material';

export interface Item {
    key: string,
    value: string,
}

export interface ItemSectionProps {
    multiSelection: boolean,
    items: Item[],
    selectedItems: string[];
    onSelectedItemsChanged: (selectedItems: string[]) => void;
}

function ItemSection(props: ItemSectionProps) {

    const onClick = (clickedItemKey: string) => {

        const {selectedItems, multiSelection, onSelectedItemsChanged} = props;

        if (selectedItems.includes(clickedItemKey)) {
            if (multiSelection) {
                const newSelectedItems = selectedItems.filter(itemKey => itemKey !== clickedItemKey);
                onSelectedItemsChanged(newSelectedItems);
            }
            else {
                onSelectedItemsChanged([]);
            }
        }
        else {
            if (multiSelection) {
                const newSelectedItems = [...selectedItems, clickedItemKey];
                onSelectedItemsChanged(newSelectedItems);
            }
            else {
                onSelectedItemsChanged([clickedItemKey]);
            }
        }
    };

    return (
        <ButtonGroup style={{padding: '0px', width: '100%'}} orientation="vertical" color="primary">
            {props.items?.map((item) => {

                const checked = props.selectedItems?.includes(item.key);

                let style = {};
                if (checked) {
                    style = {
                        fontWeight: 'bold',
                        backgroundColor: '#ddd'
                    };
                }

                return (
                    <Button
                        key={item.key}
                        sx={{
                            textTransform: 'none',
                            justifyContent: 'flex-start',
                            padding: 0
                        }}
                        onClick={() => onClick(item.key)}
                        value={item.key}
                        style={style}
                    ><Radio color="primary" checked={checked} size="small"/>{item.value}</Button>
                );
            })}
        </ButtonGroup>
    );
}

export default ItemSection;
