import {AxiosInstance, AxiosResponse} from 'axios';
import {CollectionResource} from '../types/mediaport/collection';
import {DeepPartial} from '../types/mediaport/util';

export class CollectionClient {

    protected readonly axiosInstance: AxiosInstance;

    constructor(axiosInstance: AxiosInstance) {
        this.axiosInstance = axiosInstance;
    }

    public createCollection(collectionName: string): Promise<any> {

        const collectionResource: DeepPartial<CollectionResource> = {
            shareType: 'PERSONAL',
            shareAuthority: 'MODIFIABLE',
            visible: true,
            editable: true,
            resourceContainer: {
                resourceKeys: [
                    {
                        type: 'name',
                        resourceValues: [
                            {
                                language: {code: 'de'},
                                value: collectionName
                            }
                        ]
                    }
                ]
            }
        };

        return this.axiosInstance.post(
            '/api/files/collections',
            collectionResource
        );
    }

    public getCollections(): Promise<any> {
        return this.axiosInstance
            .get(
                `/api/files/collections?size=50`
            );
    }

    public delete(id: string | number): Promise<AxiosResponse<void>> {
        return this.axiosInstance
            .delete(`/api/files/collections/${id}`);
    }

    public addMPObjectToCollection(mpObjectId: string, collectionId: string): Promise<void> {
        return this.postMPObjectToCollection(mpObjectId, collectionId, 'ADD');
    }

    public removeMPObjectFromCollection(mpObjectId: string, collectionId: string): Promise<void> {
        return this.postMPObjectToCollection(mpObjectId, collectionId, 'REMOVE');
    }

    private postMPObjectToCollection(mpObjectId: string, collectionId: string, action: 'ADD' | 'REMOVE'): Promise<void> {
        return this.axiosInstance.post(
            '/api/files/collections/' + collectionId + '/entries',
            {
                'action': action,
                'ids': [parseInt(mpObjectId)]
            }
        );
    }
}
