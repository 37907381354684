import {GetApp} from '@mui/icons-material';
import {Button, ButtonProps, Hidden, styled} from '@mui/material';
import React, {useContext, useState} from 'react';
import AppContext from '../../../AppContext';
import LoadingIndicator from '../../common/LoadingIndicator';
import DownloadDialog from './DownloadDialog';

interface Props extends ButtonProps {}

const ResponsiveButton = styled(Button)(({theme}) => ({
    [theme.breakpoints.down('md')]: {
        '& .MuiButton-startIcon': {
            margin: 0
        }
    }
}));

function DownloadButton(props: Props) {

    const context = useContext(AppContext);

    const {...buttonProps} = props;

    const [downloadDialogOpen, setDownloadDialogOpen] = useState(false);

    const downloadInProgress = context.downloads.filter(d => !(d.state === 'FINISHED' || d.state === 'FAILED')).length > 0;

    return (
        <>
            <DownloadDialog
                open={downloadDialogOpen}
                downloads={context.downloads}
                onCancel={() => setDownloadDialogOpen(false)}
            />

            <ResponsiveButton
                {...buttonProps}
                startIcon={downloadInProgress ? <LoadingIndicator/> : <GetApp/>}
                onClick={() => setDownloadDialogOpen(true)}>
                <Hidden mdDown>Downloads</Hidden>
            </ResponsiveButton>
        </>
    );
}

export default DownloadButton;
