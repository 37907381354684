import {Add, Remove} from '@mui/icons-material';
import {Divider, ListItem, ListItemText} from '@mui/material';
import {ReactNode, useState} from 'react';

interface Props {
    sectionKey: string,
    sectionName: string,
    children: ReactNode;
}

function ToggleSection(props: Props) {

    const [expanded, setExpanded] = useState(localStorage.getItem('sidebar.' + props.sectionKey + '.expanded') === `${true}`);

    const toggleSection = () => {
        const newExpandedState = !expanded;
        setExpanded(newExpandedState);
        localStorage.setItem('sidebar.' + props.sectionKey + '.expanded', `${newExpandedState}`);
    };

    return (
        <>
            <ListItem button onClick={toggleSection}>
                <ListItemText primary={props.sectionName}/>
                {expanded ? <Remove fontSize={'small'}/> : <Add fontSize={'small'}/>}
            </ListItem>

            {expanded && (
                <ListItem>
                    {props.children}
                </ListItem>
            )}

            <Divider/>
        </>
    );
}

export default ToggleSection;
