import {keyframes} from '@emotion/react';
import {Box} from '@mui/material';

const bubbleAnimation = keyframes({
    from: {
        backgroundPosition: '0 0'
    },
    to: {
        backgroundPosition: '0 -72px'
    }
});

function LoadingIndicator() {
    return <Box sx={{
        display: 'inline-block',
        position: 'relative',
        backfaceVisibility: 'hidden',
        width: '27px',
        height: '9px',
        verticalAlign: 'middle',
        backgroundImage: 'url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADYAAABICAMAAAH9tMbIAAAA4VBMVEUAAAD///8AAAD///8AAAAAAAD///8AAAD///////8AAAD///////8AAAD///8AAAD///8AAAD///8AAAD///8AAAD///8AAAD///8AAAD///8AAAD///8AAAD///8AAAD///8AAAD///8AAAD///8AAAD///8AAAD///8AAAD///8AAAAAAAAAAAAAAAD///////////8AAAD///8AAAD///8AAAD///////////8AAAD///8AAAD///8AAAAAAAAAAAD///////8AAAD///8AAAD///8AAAAAAAAAAAD///9yuSr9AAAASXRSTlMAAAEBAgUFBgYJDg4eJiYnJygoKSkyMjc3ODg8PD09YWFiYmhoaWlycnR0lpiZp6e8vcLCw8PExMbHzs7n5+rr8PDy+Pj5+fr7dq3TigAAAtBJREFUSMfdlGd72kAQhOeVkBIbS+m9UCScQktssAnuBpe7//+D8uEECOGYBBLnSfRFe8/c3Ozs3p5wnxb+EuE5QmpVL62QPqRnYyERp3X9kFf4v2sKQDudNoCGe68AlETezbyhEglSDkgRRpEElpQY8UZNCULOc6TCGastG04oZpu4gAop0ZAUIC1g7ZYiJVgfiBd5TYmQra3F1fxOTx0ZqpTLvl08padDUsKQ8SIWqUcMUNctDm9fSZEa7pSCh1ur9gtY27SkKJLUAKz1YRuI42VnHr5QYEwgDYHxGKy1kKbLeD1P6nSktmlBvQ7lyzK+tWt4IJAUBJI+TTY+Bx4/XMZryHn3dpxl571aXcYbSkoSOe9pChzwM96TTE/JRC/9qVqviLkUJSXJLEU44A/pGeNJziN8s4OZxwxrZNhgim2voxcEUum4JEnAW+Drlwk2GknX5irzfvSSDWs3Mu9BIJVOJrz3c7xVc6nVpM2LTXnGAH0ful1XBFGp5LHdDBvYwR/se3AaSKNR1vf9Prw++oiQJD2T9OgBEAJhCHxeR89daUm12uxK4/enWENSpcL0acHf5T+581PvUZTz7u781HsU5byvc+eX0KIkkiQpHzg4TuMbAwFRY7I7F2SbtuMbgzWSXI1Wah43Syp+AGx9Pf+y9QPatTHmKpCkZJg4TjJMALDWWrsBkB6kjuACQal5sora3ZaEzkVnU5LXNsa0vLl2dy+7ZcD/Zq0d+PPtvoHWKNAGM9rfaTe6lxU9eBNIkkrN42bJ4fezfeHbMN8SAXunvUCSXo0ORy8kBdfGmCsA9s/6IcDr8dH4JbBhrbW2qPZuonayoPZ+Qe1uSzIZaq/Wq3mStNm56DyZG2q/3q/7AOXuZffp/HR7lZ1KkZYNtV/drRZpd+ttRVp+qF3/9k57+VnOBeH+WZ9/yNt//Jj/tnZPH/PFds8e8ztM8juCDmO51jmt/QAAAABJRU5ErkJggg==) !important;',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: '0 0',
        animation: `${bubbleAnimation} .8s steps(8) infinite`
    }
    }/>;
}

export default LoadingIndicator;
