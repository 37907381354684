import {useEffect, useState} from 'react';
import {enumerationClient} from '../clients/clients';
import {EnumerationResource} from '../types/mediaport/enumeration';

export default function useLoadEnumeration(enumerationName: string) {

    const [enumeration, setEnumeration] = useState<EnumerationResource>();

    useEffect(
        () => {
            enumerationClient
                .searchByName(enumerationName)
                .then(enumeration => {
                    setEnumeration(enumeration);
                })
                .catch(e => {
                    console.error('Failed to load enumeration.', e);
                });
        }, [enumerationName]);

    return enumeration;
}