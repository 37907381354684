import {useEffect} from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import AppContext, {AppConfig} from '../AppContext';
import Chooser from '../components/Chooser';
import GlobalSnackbar from '../components/common/GlobalSnackbar';
import Gallery from '../components/Gallery';
import useCollections from '../hooks/useCollections';
import useDownloads from '../hooks/useDownloads';
import useFtpUploadUrl from '../hooks/useFtpUploadUrl';
import useSnackbar from '../hooks/useSnackbar';

export interface Props {
    apiBaseUrl: string,
    apiKey: string,
    secret: string,
    logout: () => void
}

export default function SecuredRoutes(props: Props) {

    const ftpUploadUrl = useFtpUploadUrl();

    const {
        snackbarOpen, snackbarText,
        showSnackbar, hideSnackbar
    } = useSnackbar();

    const {
        downloads,
        updateDownloads
    } = useDownloads();

    const {
        collections, updateCollections,
        defaultCollectionId, updateDefaultCollectionId
    } = useCollections();

    const appConfig: AppConfig = {

        apiBaseUrl: props.apiBaseUrl,
        apiKey: props.apiKey,
        secret: props.secret,

        showSnackbar: text => showSnackbar(text),

        downloads: downloads,
        updateDownloads: () => updateDownloads(),

        collections: collections,
        updateCollections: () => updateCollections(),

        defaultCollectionId: defaultCollectionId,
        updateDefaultCollectionId: (collectionId: string) => updateDefaultCollectionId(collectionId),

        ftpUploadUrl: ftpUploadUrl
    };

    return (
        <AppContext.Provider value={appConfig}>
            <Switch>
                <Route path="/home">
                    {(ftpUploadUrl !== undefined) && <Chooser/>}
                </Route>
                <Route path="/gallery">
                    <GlobalSnackbar open={snackbarOpen} text={snackbarText} hideSnackbar={hideSnackbar}/>
                    <Gallery/>
                </Route>
                <Route path="/logout">
                    <Logout logout={props.logout}/>
                </Route>
                {ftpUploadUrl !== undefined && ftpUploadUrl !== '' &&
                    <Redirect to="/home"/>
                }
                {ftpUploadUrl !== undefined && ftpUploadUrl === '' &&
                    <Redirect to="/gallery"/>
                }
            </Switch>
        </AppContext.Provider>
    );
}

function Logout(props: { logout: () => void }) {
    const {logout} = props;
    useEffect(() => logout(), []); // eslint-disable-line
    return <Redirect to={'/login'}/>;
}
