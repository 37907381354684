import {Add, ArrowDropDown} from '@mui/icons-material';
import {Alert, Button, ButtonGroup, ClickAwayListener, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grow, MenuItem, MenuList, Paper, Popper, styled, useMediaQuery, useTheme} from '@mui/material';
import {grey} from '@mui/material/colors';
import React from 'react';

export type KeyValueOption = {
    key: string;
    value: string;
}

interface Props {
    options: KeyValueOption[];
    defaultOption?: KeyValueOption;
    defaultOptionMissingText: string;
    onClick: (option: KeyValueOption) => void;
}

function SplitButton(props: Props) {

    const [open, setOpen] = React.useState(false);
    const [errorDialogOpen, setErrorDialogOpen] = React.useState(false);

    const anchorRef = React.useRef<HTMLDivElement>(null);

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    // ==========================================================================================

    const handleErrorDialogClose = () => {
        setErrorDialogOpen(false);
    };

    const handleDefaultButtonClick = () => {

        if (props.defaultOption) {
            props.onClick(props.defaultOption);
        }
        else {
            setErrorDialogOpen(true);
        }
    };

    const handleDropDownItemClick = (
        event: React.MouseEvent<HTMLLIElement, MouseEvent>,
        index: number,
    ) => {
        props.onClick(props.options[index]);
        setOpen(false);
    };

    const handleDropDownToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleDropDownClose = (event: MouseEvent | TouchEvent) => {
        if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
            return;
        }
        setOpen(false);
    };

    // ==========================================================================================

    const StyledButton = styled(Button)({
        borderColor: grey[800],
        borderWidth: 'thin',
        color: 'white',
        backgroundColor: 'rgba(0,0,0,0.7)',
        minHeight: 0,
        minWidth: 0,
        textTransform: 'none',
        transitionDuration: '0ms',
        '&:hover': {
            transitionDuration: '0ms',
            opacity: 1,
            color: 'black',
            borderColor: 'black',
            backgroundColor: 'white',
        }
    });

    return (
        <>
            {errorDialogOpen && (
                <Dialog open={errorDialogOpen} onClose={() => handleErrorDialogClose()} fullScreen={fullScreen}>
                    <DialogTitle id="form-dialog-title">Hinweis</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            <Alert severity="error">{props.defaultOptionMissingText}</Alert>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button color="primary" variant="contained"
                                onClick={() => handleErrorDialogClose()}>
                            OK
                        </Button>
                    </DialogActions>
                </Dialog>
            )}

            <ButtonGroup variant="outlined" color="primary" ref={anchorRef}>
                <StyledButton size={'small'}
                              onClick={handleDefaultButtonClick}><Add/></StyledButton>
                <StyledButton size={'small'}
                              onClick={handleDropDownToggle}><ArrowDropDown/></StyledButton>
            </ButtonGroup>

            <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition
                    disablePortal style={{zIndex: 100}}>
                {({TransitionProps, placement}) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                        }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleDropDownClose}>
                                <MenuList id="split-button-menu">
                                    {props.options.map((option, index) => (
                                        <MenuItem
                                            key={option.key}
                                            selected={option.key === props.defaultOption?.key}
                                            onClick={(event) => handleDropDownItemClick(event, index)}
                                        >
                                            {option.value}
                                        </MenuItem>
                                    ))}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </>
    );
}

export default SplitButton;
