import {AxiosInstance} from 'axios';
import {DownloadResource} from '../types/mediaport/download';

export class DownloadClient {

    protected readonly axiosInstance: AxiosInstance;

    constructor(axiosInstance: AxiosInstance) {
        this.axiosInstance = axiosInstance;
    }

    public getLatestDownloads(): Promise<DownloadResource[]> {
        return this.axiosInstance
            .get(
                '/api/downloads',
                {
                    'params': {
                        'page': 0,
                        'size': 25,
                        'type': 'DOWNLOAD',
                        'order': 'ORDER_DATE',
                        'asc': false
                    }
                }
            )
            .then(result => {
                return result.data['content'] as DownloadResource[];
            });
    }
}
