import {Box, Button, Grid} from '@mui/material';
import React from 'react';

type Color = {
    key: string
    code: string
    name: string
}

const colors: Color[] = [
    {key: 'white', name: 'Weiß', code: '#fff'},
    {key: 'gray', name: 'Grau', code: '#808080'},
    {key: 'black', name: 'Schwarz', code: '#000'},
    {key: 'red', name: 'Rot', code: '#f00'},
    {key: 'green', name: 'Grün', code: '#0f0'},
    {key: 'blue', name: 'Blau', code: '#00f'},
    {key: 'yellow', name: 'Gelb', code: '#ff0'},
    {key: 'orange', name: 'Orange', code: '#ff8000'},
    {key: 'brown', name: 'Braun', code: '#804000'},
    {key: 'cyan', name: 'Türkis', code: '#0ff'},
    {key: 'pink', name: 'Pink', code: '#ff1494'},
    {key: 'purple', name: 'Lila', code: '#93f'},
];

interface Props {
    multiSelection: boolean;
    selectedColors: string[];
    onSelectedColorsChange: (colors: string[]) => void;
}

function ColorPicker(props: Props) {

    const {selectedColors, multiSelection, onSelectedColorsChange} = props;

    const onClick = (color: string) => {
        if (selectedColors.includes(color)) {
            if (multiSelection) {
                onSelectedColorsChange(selectedColors.filter(item => item !== color));
            }
            else {
                onSelectedColorsChange([]);
            }
        }
        else {
            if (multiSelection) {
                onSelectedColorsChange([...selectedColors, color]);
            }
            else {
                onSelectedColorsChange([color]);
            }
        }
    };

    return (
        <Grid container spacing={1}>
            {colors.map((color) => {

                let style = {};
                if (selectedColors.includes(color.key)) {
                    style = {
                        borderColor: color.key === 'white' ? 'default' : color.code,
                        backgroundColor: '#ddd'
                    };
                }

                return (
                    <Grid item xs={6} key={color.key}>
                        <Button
                            sx={{
                                width: '100%',
                                borderWidth: '1px',
                                justifyContent: 'flex-start',
                                alignContent: 'center',
                                textTransform: 'none',
                                paddingLeft: 1
                            }}
                            variant={'outlined'}
                            style={style}
                            onClick={() => onClick(color.key)}>
                            <Box sx={{borderColor: 'gray', backgroundColor: color.key, padding: 1}} border={1} borderRadius={'15%'} width={16} height={16}/>
                            <Box sx={{paddingLeft: 1}}>{color.name}</Box>
                        </Button>
                    </Grid>
                );
            })}
        </Grid>
    );
}

export default ColorPicker;
