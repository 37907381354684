import {Box, Button, Grid, Tooltip} from '@mui/material';
import useLoadEnumeration from '../../../hooks/useLoadEnumeration';
import {LocalizableResource} from '../../../types/mediaport/localization';
import {getLocalizableString} from '../../../utils/ResourceUtils';

export type Columns = 1 | 2 | 3;

interface Props {
    multiSelection: boolean,
    enumerationName: string,
    selectedItems: string[];
    onSelectedItemsChanged: (selectedValues: string[]) => void;
    columns: Columns;
}

function EnumerationSection2(props: Props) {

    const enumeration = useLoadEnumeration(props.enumerationName);

    const onClick = (clickedItemKey: string) => {

        const {selectedItems, multiSelection, onSelectedItemsChanged} = props;

        if (selectedItems.includes(clickedItemKey)) {
            if (multiSelection) {
                const newSelectedItems = selectedItems.filter(itemKey => itemKey !== clickedItemKey);
                onSelectedItemsChanged(newSelectedItems);
            }
            else {
                onSelectedItemsChanged([]);
            }
        }
        else {
            if (multiSelection) {
                const newSelectedItems = [...selectedItems, clickedItemKey];
                onSelectedItemsChanged(newSelectedItems);
            }
            else {
                onSelectedItemsChanged([clickedItemKey]);
            }
        }
    };

    return (
        <Grid container spacing={1}>
            {enumeration?.enumerationValues?.map((ev, idx) => {

                const item = ev.value;
                const translatedValue = getLocalizableString(ev as LocalizableResource, 'name', 'de');

                let style = {};
                if (props.selectedItems?.includes(item)) {
                    style = {
                        fontWeight: 'bold',
                        backgroundColor: '#ddd'
                    };
                }

                return (
                    <Grid item xs={getGridSize(props.columns)} key={idx}>
                        <Tooltip title={translatedValue ?? ''} enterDelay={500} arrow>
                            <Button
                                variant={'outlined'}
                                onClick={() => onClick(item)}
                                value={item}
                                sx={{
                                    width: '100%',
                                    justifyContent: 'flex-start',
                                    paddingLeft: 1
                                }}
                                style={style}
                            >
                                <Box sx={{
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis'
                                }}>{translatedValue}</Box>
                            </Button>
                        </Tooltip>
                    </Grid>
                );
            })}
        </Grid>
    );
}

function getGridSize(columns: Columns) {
    switch (columns) {
        case 1:
            return 12;
        case 2:
            return 6;
        case 3:
            return 4;
    }
}

export default EnumerationSection2;
