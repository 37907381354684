import {Redirect, Route, Switch} from 'react-router-dom';
import {AuthenticationResult} from '../types/mediaport/auth';

import Login from '../components/Login';

export interface Props {
    apiBaseUrl: string,
    login: (username: string, password: string) => Promise<AuthenticationResult>;
}

export default function PublicRoutes(props: Props) {

    const {apiBaseUrl, login} = props;

    return (
        <Switch>
            <Route path="/login">
                <Login apiBaseUrl={apiBaseUrl} login={login}/>
            </Route>
            <Redirect to="/login"/>
        </Switch>
    );
}
