import {Box, FormControl, FormControlLabel, List, ListItem, Radio, RadioGroup, Slide, Typography} from '@mui/material';
import ColorSection from './ColorSection';
import DateSection from './DateSection';
import EnumerationSection2, {Columns} from './EnumerationSection2';
import FilterSection from './FilterSection';
import ItemSection from './ItemSection';
import LicenseWorkflowSection from './LicenseWorkflowSection';
import ToggleSection from './ToggleSection';

export interface SidebarFilters {
    [key: string]: any;
}

interface SidebarProps {
    sidebarExpanded: boolean,
    sortType: string,
    sidebarFilters: SidebarFilters,
    onSortTypeChanged: (sortAttribute: string) => void;
    onSidebarFiltersChange: (sidebarFilters: SidebarFilters) => void;
}

function Sidebar(props: SidebarProps) {

    const onSidebarFilterRemoved = (filter: string, removedItem: string) => {
        const newItems = props.sidebarFilters[filter].filter(item => item !== removedItem);
        onSidebarFiltersChanged(filter, newItems);
    };

    const onSidebarFiltersChanged = (filterKey: string, selectedItems: string[]) => {
        props.onSidebarFiltersChange({
            ...props.sidebarFilters,
            [filterKey]: selectedItems
        });
    };

    const newToggleSection = (sectionKey: string, sectionName: string, enumerationName: string, selectedItemsKey: string, columns: Columns) => {
        return <ToggleSection sectionKey={sectionKey} sectionName={sectionName}>
            <EnumerationSection2
                enumerationName={enumerationName}
                selectedItems={props.sidebarFilters[selectedItemsKey] ?? []}
                onSelectedItemsChanged={values => onSidebarFiltersChanged(selectedItemsKey, values)}
                multiSelection={true}
                columns={columns}/>
        </ToggleSection>;
    };

    const filterCount = Object
        .keys(props.sidebarFilters)
        .map(k => props.sidebarFilters[k]?.length ?? 0)
        .reduce((a, b) => a + b, 0);

    return (

        <Slide timeout={100} direction="right" in={props.sidebarExpanded} mountOnEnter unmountOnExit>

            <List sx={{
                width: '100%',
                minWidth: 300,
                maxWidth: 300,
                paddingTop: 0,
                backgroundColor: theme => theme.palette.background.paper,
            }}>

                <Box bgcolor="gray" color="white" p={2}>
                    <Typography variant="h6">
                        Filter
                    </Typography>
                </Box>
                {filterCount > 0 && (
                    <ListItem>
                        <FilterSection sidebarFilters={props.sidebarFilters} onFilterRemove={onSidebarFilterRemoved}/>
                    </ListItem>
                )}

                <ToggleSection sectionKey={'SearchOrder'} sectionName={'Sortieren nach'}>
                    <FormControl>
                        <RadioGroup
                            defaultValue={props.sortType}
                            name="sortType-group"
                            onChange={(ev, value) => {props.onSortTypeChanged(value);}}>
                            <FormControlLabel value="newest" control={<Radio/>} label="Neuste"/>
                            <FormControlLabel value="mostPopular" control={<Radio/>} label="Am Beliebtesten"/>
                        </RadioGroup>
                    </FormControl>
                </ToggleSection>

                {newToggleSection('Signet', 'Signet', 'file.signet', 'signet', 2)}
                {newToggleSection('Orientation', 'Ausrichtung', 'file.orientation', 'orientation', 2)}

                <ToggleSection sectionKey={'ResolutionSection'} sectionName={'Bildauflösung'}>
                    <ItemSection
                        items={[
                            {key: '12 MP', value: '12 MP und mehr'},
                            {key: '16 MP', value: '16 MP und mehr'},
                            {key: '21 MP', value: '21 MP und mehr'},
                        ]}
                        selectedItems={props.sidebarFilters['resolution'] ?? []}
                        onSelectedItemsChanged={keys => onSidebarFiltersChanged('resolution', keys)}
                        multiSelection={false}/>
                </ToggleSection>

                <ToggleSection sectionKey={'ColorSection'} sectionName={'Farbauswahl'}>
                    <ColorSection
                        selectedColors={props.sidebarFilters['color'] ?? []}
                        onSelectedColorsChange={colors => onSidebarFiltersChanged('color', colors)}
                    />
                </ToggleSection>

                {newToggleSection('LicenceType', 'Art der Lizenz', 'file.licenceType', 'licenceType', 2)}

                <ToggleSection sectionKey={'LicenseWorkflowSection'} sectionName={'Lizenzstatus'}>
                    <LicenseWorkflowSection
                        selectedItems={props.sidebarFilters['license'] ?? []}
                        onSelectedItemsChanged={licenseType => onSidebarFiltersChanged('license', licenseType)}
                    />
                </ToggleSection>

                {newToggleSection('UsageRights', 'Nutzungsrechte', 'file.usageRights', 'usageRights', 2)}

                {newToggleSection('AmountOfPersons', 'Anzahl der Personen', 'file.amountOfPersons', 'amountOfPersons', 2)}
                {newToggleSection('AgeGroup', 'Altersgruppe', 'file.ageGroup', 'ageGroup', 2)}
                {newToggleSection('Shooting', 'Aufgenommen in Shooting', 'file.shooting', 'shooting', 1)}
                {newToggleSection('Agentur', 'Agentur', 'file.agency', 'agency', 1)}
                {newToggleSection('PersonComposition', 'Personenkompositionen', 'file.personComposition', 'personComposition', 2)}
                {newToggleSection('ImageStyle', 'Bildstil', 'file.imageStyle', 'imageStyle', 2)}
                {newToggleSection('ImageMood', 'Bildstimmung', 'file.imageMood', 'imageMood', 2)}
                {newToggleSection('StoreSituation', 'Warenhaus-Situationen', 'file.storeSituation', 'storeSituation', 2)}
                {newToggleSection('Season', 'Saison / Anlass', 'file.season', 'season', 2)}
                {newToggleSection('GoodsCategory', 'Warenkategorie', 'file.goodsCategory', 'goodsCategory', 1)}
                {newToggleSection('Emotions', 'Emotionen', 'file.emotions', 'emotion', 2)}
                {newToggleSection('ImageComposition', 'Bildkomposition', 'file.imageComposition', 'imageComposition', 2)}

                <ToggleSection sectionKey={'DateSection'} sectionName={'Erstellungsdatum'}>
                    <DateSection
                        dateStringFrom={props.sidebarFilters['dateFrom']?.[0] ?? ''}
                        dateStringTo={props.sidebarFilters['dateTo']?.[0] ?? ''}
                        onChangeDateStringFrom={date => onSidebarFiltersChanged('dateFrom', date ? [date] : [])}
                        onChangeDateStringTo={date => onSidebarFiltersChanged('dateTo', date ? [date] : [])}
                    />
                </ToggleSection>

            </List>
        </Slide>
    );
}

export default Sidebar;
