import {Grid} from '@mui/material';
import NativeDatePicker from '../../common/NativeDatePicker';

interface DateSectionProps {
    dateStringFrom: string;
    dateStringTo: string;
    onChangeDateStringFrom: (dateFrom: string) => void;
    onChangeDateStringTo: (dateFrom: string) => void;
}

export default function DateSection(props: DateSectionProps) {
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <NativeDatePicker label={'von'} dateString={props.dateStringFrom} onChangeDateString={dateString => props.onChangeDateStringFrom(dateString)}/>
            </Grid>
            <Grid item xs={12}>
                <NativeDatePicker label={'bis'} dateString={props.dateStringTo} onChangeDateString={dateString => props.onChangeDateStringTo(dateString)}/>
            </Grid>
        </Grid>
    );
}
