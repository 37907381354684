import {Person, Visibility, VisibilityOff, VpnKey} from '@mui/icons-material';
import {Alert, Box, Button, Card, CircularProgress, Container, CssBaseline, FormControl, Grid, IconButton, Input, InputAdornment, InputLabel, Link, ThemeProvider, Typography} from '@mui/material';
import {green} from '@mui/material/colors';
import {useState} from 'react';

import BackgroundImage from '../images/login_background.jpg';
import LogoSM from '../logo.svg';
import Logo from '../logo_vertical.svg';
import {AuthenticationResult} from '../types/mediaport/auth';
import {theme} from '../utils/theme';

interface Props {
    login: (username: string, password: string) => Promise<AuthenticationResult>;
    apiBaseUrl: string;
}

function Login(props: Props) {

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState<string>();
    const [showPassword, setShowPassword] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleUsernameChange = (event) => {
        setErrorMessage(undefined);
        setUsername(event.target.value);
    };

    const handlePasswordChange = (event) => {
        setErrorMessage(undefined);
        setPassword(event.target.value);
    };

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const validateForm = () => {
        return username.length > 0 && password.length > 0;
    };

    const handleSubmit = (e) => {

        e.preventDefault();

        setLoading(true);

        props.login(username, password)
            .catch(err => {

                if (!err.response) {
                    setErrorMessage('Keine Verbindung');
                    setLoading(false);
                }
                else {
                    setErrorMessage('Benutzername oder Passwort falsch');
                    setLoading(false);
                }
            });
    };

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline/>
            <Box sx={{
                background: `url(${BackgroundImage}) no-repeat center / cover`,
                minHeight: '100vh',
                display: 'flex',
                alignItems: 'center',
                paddingTop: 3,
                paddingBottom: 3,
            }}>
                <Container component="main" maxWidth="md">
                    <Card sx={{padding: 5}}>
                        {
                            errorMessage &&
                            <Alert severity="error" sx={{marginBottom: 2}}>
                                {errorMessage}
                            </Alert>
                        }
                        <Grid container spacing={5} alignItems="center" justifyContent="center">
                            <Grid item sm={6} sx={{textAlign: 'center', color: 'primary.main'}}>
                                <picture>
                                    <source media="(max-width: 400px)" srcSet={LogoSM}/>
                                    <Box component={'img'} src={Logo} alt="Logo" sx={{
                                        mt: 1,
                                        mb: 1,
                                        maxWidth: '220px'
                                    }}/>
                                </picture>
                                <hr/>
                                <h2>Look how beautiful we are</h2>
                            </Grid>
                            <Grid item sm={6}>
                                <Typography variant="h5" component="h2" gutterBottom>
                                    Willkommen
                                </Typography>
                                <form method={'POST'} onSubmit={handleSubmit}>
                                    <Grid container spacing={1} alignItems="flex-end">
                                        <Grid item>
                                            <Person/>
                                        </Grid>
                                        <Grid item xs>
                                            <FormControl fullWidth sx={{mt: 1}}>
                                                <InputLabel
                                                    htmlFor="standard-adornment-username">Benutzername</InputLabel>
                                                <Input
                                                    autoComplete={'username'}
                                                    id="standard-adornment-username"
                                                    value={username}
                                                    onChange={handleUsernameChange}
                                                />
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={1} alignItems="flex-end">
                                        <Grid item>
                                            <VpnKey/>
                                        </Grid>
                                        <Grid item xs>
                                            <FormControl fullWidth sx={{mt: 1}}>
                                                <InputLabel htmlFor="standard-adornment-password">Passwort</InputLabel>
                                                <Input
                                                    id="standard-adornment-password"
                                                    type={showPassword ? 'text' : 'password'}
                                                    value={password}
                                                    onChange={handlePasswordChange}
                                                    autoComplete={'current-password'}
                                                    endAdornment={
                                                        <InputAdornment position="end">
                                                            <IconButton onClick={handleClickShowPassword}>
                                                                {showPassword ?
                                                                    <Visibility/> :
                                                                    <VisibilityOff/>}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    }
                                                />
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2} alignItems="center" sx={{mt: 4}}>
                                        <Grid item xs>
                                            <Button
                                                disabled={!validateForm() || loading}
                                                type="submit"
                                                variant="outlined"
                                                color="primary"
                                                onClick={(event) => handleSubmit(event)}
                                            >
                                                Anmelden
                                                {loading && <CircularProgress size={24} sx={{
                                                    color: green[500],
                                                    position: 'absolute',
                                                    top: '50%',
                                                    left: '50%',
                                                    marginTop: -12,
                                                    marginLeft: -12,
                                                }}/>}
                                            </Button>
                                        </Grid>
                                        <Grid item xs>
                                            <Box textAlign="right">
                                                <Link href={props.apiBaseUrl + '/lostPassword.html'} target={'_blank'} variant="body2">
                                                    Passwort vergessen?
                                                </Link>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </form>
                            </Grid>
                        </Grid>
                    </Card>
                </Container>
            </Box>
        </ThemeProvider>
    );
}

export default Login;
