import {Alert, Snackbar} from '@mui/material';
import React from 'react';

interface Props {
    open: boolean;
    text?: string;
    hideSnackbar: () => void;
}

function GlobalSnackbar(props: Props) {

    const {open, text, hideSnackbar} = props;

    return (
        <Snackbar
            open={open}
            autoHideDuration={3000}
            onClose={hideSnackbar}
            anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
        >
            <Alert onClose={hideSnackbar} severity="success" variant="filled">
                {text}
            </Alert>
        </Snackbar>
    );
}

export default GlobalSnackbar;
