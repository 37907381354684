import {Download} from '@mui/icons-material';
import {Button, ButtonGroup, ButtonProps, styled} from '@mui/material';
import {grey} from '@mui/material/colors';
import React from 'react';

interface Props extends ButtonProps {
    onClick: () => void;
}

function MPDownloadButton(props: Props) {

    const {onClick} = props;

    const StyledButton = styled(Button)({
        borderColor: grey[800],
        borderWidth: 'thin',
        color: 'white',
        backgroundColor: 'rgba(0,0,0,0.7)',
        minHeight: 0,
        minWidth: 0,
        textTransform: 'none',
        transitionDuration: '0ms',
        '&:hover': {
            transitionDuration: '0ms',
            opacity: 1,
            color: 'black',
            borderColor: 'black',
            backgroundColor: 'white',
        }
    });

    return (
        <ButtonGroup variant="outlined" color="primary">
            <StyledButton variant={'outlined'}
                          size={'small'}
                          onClick={onClick}>
                <Download/>
            </StyledButton>
        </ButtonGroup>
    );
}

export default MPDownloadButton;
