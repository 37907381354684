import {CssBaseline, ThemeProvider} from '@mui/material';
import React from 'react';
import {BrowserRouter as Router} from 'react-router-dom';
import './App.css';
import useLogin from './hooks/useLogin';
import PublicRoutes from './routes/PublicRoutes';
import SecuredRoutes from './routes/SecuredRoutes';
import {theme} from './utils/theme';

function App() {

    const {
        apiBaseUrl,
        authenticated,
        apiKey, secret,
        login, logout
    } = useLogin();

    return (
        <ThemeProvider theme={theme}>
            <Router>

                <CssBaseline/>

                {!authenticated && (
                    <PublicRoutes
                        apiBaseUrl={apiBaseUrl}
                        login={login}
                    />
                )}

                {authenticated && (
                    <SecuredRoutes
                        apiBaseUrl={apiBaseUrl}
                        apiKey={apiKey!}
                        secret={secret!}
                        logout={logout}
                    />
                )}

            </Router>
        </ThemeProvider>
    );
}

export default App;
