import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, useMediaQuery, useTheme} from '@mui/material';
import {DialogProps} from '@mui/material/Dialog/Dialog';
import {useState} from 'react';
import {collectionClient} from '../../../clients/clients';

interface Props extends DialogProps {
    onCollectionCreated: (name: string) => void;
    onCancel: () => void;
}

function PinboardCreateDialog(props: Props) {

    const {onCollectionCreated, onCancel} = props;

    const [value, setValue] = useState<string>('');

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const onCreate = () => {
        collectionClient
            .createCollection(value)
            .then(() => {
                onCollectionCreated(value);
                setValue('');
            })
            .catch(e => {
                setValue('');
                onCancel();
                console.log('FAILED.', e);
            });
    };

    const handleCancel = () => {
        setValue('');
        onCancel();
    };

    return (

        <Dialog {...props} onClose={handleCancel} fullScreen={fullScreen}>
            <DialogTitle id="form-dialog-title">Pinnwand erstellen</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Bitte geben sie ihrer Pinnwand einen Namen.
                </DialogContentText>
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Name der Pinnwand"
                    fullWidth
                    value={value}
                    onChange={e => setValue(e.target.value)}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancel}>
                    Abbrechen
                </Button>
                <Button onClick={onCreate} color="primary" variant="contained">
                    Erstellen
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default PinboardCreateDialog;
