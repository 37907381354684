import {Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, Grid, ImageList, ImageListItem, Typography, useMediaQuery, useTheme} from '@mui/material';
import {DialogProps} from '@mui/material/Dialog/Dialog';
import React, {useState} from 'react';
import useLoadMPObject from '../../hooks/useLoadMPObject';
import useSearchMPObjects from '../../hooks/useSearchMPObjects';
import {getAttributeValue, getBooleanValue, getPreviewID, getWorkflowState} from '../../utils/MediaPortUtils';
import SingleFileDownloadDialog from '../gallery/download/SingleFileDownloadDialog';
import MPCollectionSplitButton from './MPCollectionSplitButton';
import MPDownloadButton from './MPDownloadButton';
import MPImage from './MPImage';
import MPImageOverlay from './MPImageOverlay';

interface Props extends DialogProps {
    fileId: string;
    previewId: string | null;
    fileName?: string;
    onClose: () => void;
}

function MPImageDialog(props: Props) {

    const {fileId, fileName, previewId, open, onClose, ...dialogProps} = props;

    const [downloadDialogOpen, setDownloadDialogOpen] = useState(false);

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <>
            {downloadDialogOpen && (<SingleFileDownloadDialog open={downloadDialogOpen} fileId={fileId} onCancel={() => setDownloadDialogOpen(false)}/>)}
            <Dialog {...dialogProps} open={open} fullScreen={fullScreen} onClose={() => onClose()}>
                <DialogContent>

                    <Box textAlign="center">
                        <Box sx={{
                            position: 'relative',
                            marginLeft: -3,
                            marginRight: -3,
                            marginTop: -2.5,
                            display: 'inline-flex',
                            '& > img': {
                                maxHeight: '80vh',
                                width: '100%',
                                height: '100%',
                                minWidth: '100%',
                            }
                        }}>
                            <MPImage previewId={previewId}/>
                            <Box sx={{
                                padding: 0.5,
                                position: 'absolute',
                                right: 0,
                                bottom: 0
                            }}>
                                <MPDownloadButton onClick={() => setDownloadDialogOpen(true)}/>
                                <MPCollectionSplitButton fileId={fileId}/>
                            </Box>
                        </Box>
                    </Box>

                    <Typography variant={'h6'}>Eigenschaften</Typography>

                    <AttributePanel fileId={fileId}/>

                    {fileId &&
                        <RelationPanel fileId={fileId} relationSide={'similarImage.images'}
                                       headline="Ähnliche Bilder"/>
                    }


                    {fileId &&
                        <RelationPanel fileId={fileId} relationSide={'imageFromSameSeries.images'}
                                       headline="Alternative Bilder aus dem Shooting"/>
                    }

                </DialogContent>
                <DialogActions>
                    <Button onClick={() => onClose()}>
                        Schließen
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

// ===============================================================================================================================================================================
// Attribute Panel
// ===============================================================================================================================================================================

interface AttributePanelProps {
    fileId: string;
}

function AttributePanel(props: AttributePanelProps) {

    const {fileId} = props;

    const loadOptions = React.useMemo(() => {
        return {
            'attributes': [
                'file.widthPixel',
                'file.heightPixel',
                'file.resolutionDpi',
                'file.usageFrom',
                'file.usageUntil',
                'file.downloadAllowed',
                'file.agency',
                'file.licenceType',
            ],
            'workflows': [
                'file.licenseWorkflow'
            ]
        };
    }, []);

    const {mpObject, loading} = useLoadMPObject('files', fileId, loadOptions);

    if (loading) {
        return <CircularProgress/>;
    }

    const calculateResolutionString = () => {
        if (mpObject) {
            return getAttributeValue(mpObject, 'file.widthPixel') + ' x ' + getAttributeValue(mpObject, 'file.heightPixel') + ' px / ' + getAttributeValue(mpObject, 'file.resolutionDpi') + ' dpi';
        }
        return '';
    };

    return (
        <Grid container>
            {[
                {
                    name: 'file.imageResolution',
                    translation: 'Bildauflösung',
                    value: calculateResolutionString()
                },
                {
                    name: 'file.usageFrom',
                    translation: 'Nutzung ab',
                    value: getAttributeValue(mpObject, 'file.usageFrom') ?? ''
                },
                {
                    name: 'file.usageUntil',
                    translation: 'Nutzung bis',
                    value: getAttributeValue(mpObject, 'file.usageUntil') ?? ''
                },
                {
                    name: 'file.downloadAllowed',
                    translation: 'Download erlaubt',
                    value: getBooleanValue(mpObject, 'file.downloadAllowed') ? 'Ja' : 'Nein'
                },
                {
                    name: 'file.agency',
                    translation: 'Agentur',
                    value: getAttributeValue(mpObject, 'file.agency')
                },
                {
                    name: 'file.licenceType',
                    translation: 'Art der Lizenz',
                    value: getAttributeValue(mpObject, 'file.licenceType')
                },
                {
                    name: 'file.licenseWorkflow',
                    translation: 'Lizenzstatus',
                    value: getWorkflowState(mpObject, 'file.licenseWorkflow')
                }
            ]
                ?.map((property) => (
                    <>
                        <Grid item xs={5}>
                            <Box fontWeight="fontWeightBold">{property.translation}:</Box>
                        </Grid>
                        <Grid item xs={7}>
                            {property.value}
                        </Grid>
                    </>
                ))
            }
        </Grid>
    );
}

// ===============================================================================================================================================================================
// Relation Panel
// ===============================================================================================================================================================================

interface RelationPanelProps {
    fileId: string;
    relationSide: string;
    headline: string;
}

function RelationPanel(props: RelationPanelProps) {

    const {fileId, relationSide, headline} = props;

    const search = React.useMemo(() => {
        return {
            'search': {
                'query': {
                    'relation': {
                        key: relationSide,
                        any: [fileId]
                    }
                },
            },
            'properties': {
                'attributes': [
                    'file.name',
                    'file.widthPixel',
                    'file.heightPixel'
                ]
            }
        };
    }, [fileId, relationSide]);

    const {items, hasMoreItems, loadMore, totalItemCount} = useSearchMPObjects('files', search, 25);

    const matches = useMediaQuery('(min-width:600px)');

    return (
        <>
            {totalItemCount !== 0 &&
                <Box sx={{marginTop: 2}}>
                    <Typography variant={'h6'}>{headline}</Typography>
                    {/* // todo dawi rowHeight vs cellHeight */}
                    <ImageList cols={4} rowHeight={matches ? 300 : 100}>
                        {items
                            .map((mpObject, index) => (

                                <ImageListItem key={index} sx={{
                                    '& img': {
                                        objectFit: 'cover',
                                        height: '100%',
                                        width: '100%',
                                    }
                                }}>
                                    <MPImage
                                        previewId={getPreviewID(mpObject)}
                                        fileWidthPixel={getAttributeValue(mpObject, 'file.widthPixel')}
                                        fileHeightPixel={getAttributeValue(mpObject, 'file.heightPixel')}
                                    />

                                    <MPImageOverlay
                                        fileId={mpObject.id}
                                        previewId={getPreviewID(mpObject)}
                                        fileName={getAttributeValue(mpObject, 'file.name')}
                                        fileWidthPixel={getAttributeValue(mpObject, 'file.widthPixel')}
                                        fileHeightPixel={getAttributeValue(mpObject, 'file.heightPixel')}
                                        showDownloadButton={false}
                                    />
                                </ImageListItem>
                            ))}
                        {hasMoreItems &&
                            <ImageListItem>
                                <Box display="flex" justifyContent="center" alignItems="center"
                                     sx={{
                                         backgroundColor: '#eee',
                                         height: '100%'
                                     }}>
                                    <Button variant={'contained'} color="primary" onClick={() => loadMore()}>weitere</Button>
                                </Box>
                            </ImageListItem>
                        }
                    </ImageList>
                </Box>
            }
        </>
    );
}

export default MPImageDialog;
