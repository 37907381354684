import {ListItem} from '@mui/material';
import React from 'react';
import FilterChip from './FilterChip';
import {SidebarFilters} from './SideBar';

interface Props {
    sidebarFilters: SidebarFilters,
    onFilterRemove: (filterKey: string, filterValue) => void;
}

function FilterSection(props: Props) {

    return (
        <>
            <ListItem
                component="ul"
                sx={{
                    display: 'flex',
                    gap: 1,
                    flexWrap: 'wrap',
                    listStyle: 'none',
                    padding: 0,
                    margin: 0,
                }}
            >
                {Object
                    .entries(props.sidebarFilters)
                    .filter(entry => entry[1].length > 0)
                    .map(entry => {

                        const filterKey = entry[0];
                        const filterValues = entry[1];

                        return filterValues.map(filterValue => {
                            return (
                                <li>
                                    <FilterChip
                                        filterKey={filterKey}
                                        filterValue={filterValue}
                                        onDelete={() => { props.onFilterRemove(filterKey, filterValue); }}
                                        onClick={() => { props.onFilterRemove(filterKey, filterValue); }}
                                    />
                                </li>
                            );
                        });
                    })
                }
            </ListItem>
        </>
    );
}

export default FilterSection;
