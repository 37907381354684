import {MPObject} from '../types/mediaport/mpobject';

export function getAttributeValue(mpObject?: MPObject, attributeName?: string): string | undefined {
    return mpObject?.attributes.find(attribute => attribute.name === attributeName)?.attributeVariantContainers[0]?.value as string;
}

export function getBooleanValue(mpObject?: MPObject, attributeName?: string): boolean | undefined {
    return mpObject?.attributes.find(attribute => attribute.name === attributeName)?.attributeVariantContainers[0]?.value as boolean;
}

export function getPreviewID(mpObject: MPObject): string | null {

    const largePreview = mpObject.previews['LARGE'];

    if (!largePreview || largePreview.length === 0) {
        return null;
    }

    return largePreview[0]['rel'];
}

export function getWorkflowState(mpObject?: MPObject, workflowName?: string): string | undefined {
    return mpObject
        ?.workflows.find(workflow => workflow.name === workflowName)
        ?.workflowElements.map(we => we.elementResourceKeys.resourceValues?.find(rv => rv.language.code === 'de')?.value)
        ?.join(', ');
}
