import {AxiosInstance, AxiosRequestConfig} from 'axios';

export interface ImageData {
    content: string;
    contentType: string;
}

export class PreviewClient {

    protected readonly axiosInstance: AxiosInstance;

    constructor(axiosInstance: AxiosInstance) {
        this.axiosInstance = axiosInstance;
    }

    public getBase64EncodedPreview(previewId: string, config?: AxiosRequestConfig): Promise<ImageData> {
        return this.axiosInstance
            .get(
                `/api/previews/${previewId}/resource`,
                {
                    ...config,
                    responseType: 'arraybuffer',
                }
            )
            .then(response => {
                return {
                    content: btoa(new Uint8Array(response.data).reduce((data, byte) => data + String.fromCharCode(byte), '')),
                    contentType: response.headers['content-type']
                } as ImageData;
            });
    }
}
