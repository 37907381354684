import {useContext} from 'react';
import AppContext from '../../AppContext';
import {collectionClient} from '../../clients/clients';
import {CollectionResource} from '../../types/mediaport/collection';
import {getLocalizableString} from '../../utils/ResourceUtils';
import SplitButton, {KeyValueOption} from './SplitButton';

interface Props {
    fileId: string;
}

function MPCollectionSplitButton(props: Props) {

    const appContext = useContext(AppContext);

    const addToCollection = (fileId: string | null, collectionId: string) => {

        if (fileId === null) {
            return;
        }

        collectionClient
            .addMPObjectToCollection(fileId, collectionId)
            .then(() => {
                appContext.showSnackbar('Datei wurde der Pinnwand hinzugefügt.');
            }).catch(e => {
            console.log('FAILED.', e);
        });
    };

    const collectionToKeyValueOption = (collection: CollectionResource): KeyValueOption => {
        const key = collection.id + '';
        const value = getLocalizableString(collection, 'name', 'de');
        return {
            key: key,
            value: value
        } as KeyValueOption;
    };

    const getSplitButtonDefaultOption = () => {
        if (appContext.collections !== undefined) {
            return appContext
                .collections
                .filter(collection => `${collection.id}` === appContext.defaultCollectionId)
                .map(collection => collectionToKeyValueOption(collection))[0];
        }
        return undefined;
    };

    const getSplitButtonOptions = () => {
        if (appContext.collections !== undefined) {
            return appContext
                .collections
                .map(collection => collectionToKeyValueOption(collection));
        }
        return [];
    };

    return (
        <SplitButton
            defaultOption={getSplitButtonDefaultOption()}
            defaultOptionMissingText={'Bitte wählen sie zuerst eine Default-Pinnwand aus.'}
            options={getSplitButtonOptions()}
            onClick={option => addToCollection(props.fileId, option.key)}
        />
    );
}

export default MPCollectionSplitButton;
