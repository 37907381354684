import {CheckCircle, Close} from '@mui/icons-material';
import {Box, Button, Drawer, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText, Radio, Typography,} from '@mui/material';
import {DialogProps} from '@mui/material/Dialog/Dialog';
import {useContext, useState} from 'react';
import AppContext from '../../../AppContext';
import {collectionClient} from '../../../clients/clients';
import {toNumber} from '../../../utils/NumberUtils';
import {getLocalizableString} from '../../../utils/ResourceUtils';
import PinboardContentDialog from './PinboardContentDialog';
import CreatePinboardDialog from './PinboardCreateDialog';

interface Props extends DialogProps {
    open: boolean;
    onClose: () => void;
}

function Pinboard(props: Props) {

    const appContext = useContext(AppContext);

    const [pinboardCreateDialogOpen, setPinboardCreateDialogOpen] = useState(false);
    const [pinboardContentCollectionId, setPinboardContentCollectionId] = useState<number>();

    const {open, onClose} = props;

    const updateCollections = () => {
        collectionClient.getCollections()
            .then(response => {
                appContext.updateCollections();
            })
            .catch(e => {
                console.log('Failed to load collections.', e);
            });
    };

    const updateDefaultCollection = (collectionId: number) => {
        appContext.updateDefaultCollectionId(`${collectionId}`);
    };

    const onCreateDialogPinboardCreated = (name: string) => {
        updateCollections();
        setPinboardCreateDialogOpen(false);
        appContext.showSnackbar(`Pinnwand "${name}" wurder erstellt.`);
    };

    const onCreateDialogClose = () => {
        setPinboardCreateDialogOpen(false);
    };

    return (
        <>

            <PinboardContentDialog
                collectionId={pinboardContentCollectionId}
                open={pinboardContentCollectionId !== undefined}
                onCancel={() => setPinboardContentCollectionId(undefined)}
            />

            <Drawer
                sx={{width: '700px'}}
                anchor={'right'}
                open={open}
                onClose={() => onClose()}
            >
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    padding: 2,
                    paddingRight: 0.5
                }}>
                    <Typography variant="h5">Pinnwände</Typography>
                    <IconButton
                        onClick={() => onClose()}
                        size="large" // todo dawi
                    >
                        <Close/>
                    </IconButton>
                </Box>

                <List>
                    {appContext.collections?.map((collection) => {
                        const labelId = `checkbox-list-secondary-label-${collection.id}`;
                        return (
                            <ListItem key={collection.id} button
                                      onClick={() => setPinboardContentCollectionId(collection.id)}>
                                <ListItemText id={labelId}
                                              primary={getLocalizableString(collection, 'name', 'de')}/>
                                <ListItemSecondaryAction>
                                    <Radio
                                        color="primary"
                                        checkedIcon={<CheckCircle/>}
                                        edge="end"
                                        onChange={() => updateDefaultCollection(collection.id)}
                                        checked={collection.id === toNumber(appContext.defaultCollectionId)}
                                    />
                                </ListItemSecondaryAction>
                            </ListItem>
                        );
                    })}
                </List>
                <Button variant={'outlined'}
                        sx={{margin: 2}}
                        onClick={() => setPinboardCreateDialogOpen(true)}>
                    Pinnwand erstellen
                </Button>

                <CreatePinboardDialog
                    open={pinboardCreateDialogOpen}
                    onCollectionCreated={onCreateDialogPinboardCreated}
                    onCancel={onCreateDialogClose}
                />
            </Drawer>
        </>
    );
}

export default Pinboard;
