import {AxiosInstance, AxiosRequestConfig} from 'axios';
import {MPObject, MPObjectSearchResult} from '../types/mediaport/mpobject';

export class MPObjectClient {

    protected readonly controller: string;
    protected readonly axiosInstance: AxiosInstance;

    constructor(controller: string, axiosInstance: AxiosInstance) {
        this.controller = controller;
        this.axiosInstance = axiosInstance;
    }

    public load(id: string, loadOptions?: any, config?: AxiosRequestConfig): Promise<MPObject> {
        return this.axiosInstance
            .post(
                `/api/${this.controller}/load/${id}`,
                loadOptions,
                config
            )
            .then(response => {
                return response.data as MPObject;
            });
    }

    public searchByQuery(search?: any, config?: AxiosRequestConfig): Promise<MPObjectSearchResult> {
        return this.axiosInstance
            .post(
                `/api/${this.controller}/search/byQuery`,
                search,
                config
            )
            .then(response => {
                return response.data as MPObjectSearchResult;
            });
    }
}
