import ColorPicker from '../../common/ColorPicker';

interface ColorSectionProps {
    selectedColors: string[],
    onSelectedColorsChange: (selectedColors: string[]) => void;
}

function ColorSection(props: ColorSectionProps) {

    return (
        <ColorPicker
            multiSelection={false}
            selectedColors={props.selectedColors}
            onSelectedColorsChange={colors => {
                props.onSelectedColorsChange(colors);
            }}
        />
    );
}

export default ColorSection;
