import {useState} from 'react';

export default function useSnackbar() {

    const [snackbarText, setSnackbarText] = useState<string>();
    const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);

    const showSnackbar = (text: string) => {
        setSnackbarText(text);
        setSnackbarOpen(true);
    };

    const hideSnackbar = () => {
        setSnackbarOpen(false);
    };

    return {snackbarText, snackbarOpen, showSnackbar, hideSnackbar};
}