import {LocalizableResource} from '../types/mediaport/localization';

export function getLocalizableString(localizable: LocalizableResource, type: string, language: string): string | undefined {
    const resourceKey = localizable?.resourceContainer?.resourceKeys?.find(resourceKey => resourceKey.type === type);
    if (resourceKey) {
        const resourceValue = resourceKey?.resourceValues?.find(resourceValue => resourceValue?.language?.code === language);
        if (resourceValue) {
            return resourceValue.value;
        }
    }
    return undefined;
}
