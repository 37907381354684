import {Box, CircularProgress} from '@mui/material';
import React from 'react';
import useLoadPreview from '../../hooks/useLoadPreview';

const IMAGE_WIDTH = 100;
const IMAGE_HEIGHT = 300;

interface Props extends React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> {
    previewId: string | null;
    fileWidthPixel?: string;
    fileHeightPixel?: string;
}

function MPImage(props: Props) {

    const {previewId, fileWidthPixel, fileHeightPixel, ...imageProps} = props;

    const {imageSource, loading} = useLoadPreview(previewId);

    const ratioStyle = {
        width: IMAGE_WIDTH,
    };

    if (fileWidthPixel != null && fileHeightPixel != null) {
        const ratio = parseInt(fileWidthPixel) / parseInt(fileHeightPixel);
        ratioStyle.width = IMAGE_WIDTH * ratio;
    }

    if (loading) {
        return (
            <Box component={'div'} style={ratioStyle} sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: IMAGE_HEIGHT,
            }}>
                <CircularProgress/>
            </Box>
        );
    }

    return <img {...imageProps} src={imageSource!} alt={`Preview ${previewId}`}/>;
}

export default MPImage;
