import ItemSection from './ItemSection';

interface Props {
    selectedItems: string[];
    onSelectedItemsChanged: (selectedItems: string[]) => void;
}

function LicenseWorkflowSection(props: Props) {
    return (
        <ItemSection
            multiSelection={false}
            items={[
                {key: 'validLicense', value: 'Gültige Lizenz'},
                {key: 'invalidLicense', value: 'Keine gültige Lizenz'}
            ]}
            selectedItems={props.selectedItems}
            onSelectedItemsChanged={props.onSelectedItemsChanged}/>
    );
}

export default LicenseWorkflowSection;
