import {Backup, ImageSearch} from '@mui/icons-material/';
import {Avatar, Box, Card, CardActionArea, CardContent, Container, Grid, styled, Typography,} from '@mui/material';
import {useContext} from 'react';
import AppContext from '../AppContext';
import BackgroundImage from '../images/login_background.jpg';
import {customThemeSettings} from '../utils/theme';

import Header from './common/Header';

const StyledAvatar = styled(Avatar)(({theme}) => ({

    backgroundColor: '#b2ccde',
    color: customThemeSettings.mainColor,
    marginRight: theme.spacing(2),

    [theme.breakpoints.up('sm')]: {

        display: 'inline-flex',
        margin: theme.spacing(4),
        width: theme.spacing(18),
        height: theme.spacing(18),

        '& > *': {
            margin: theme.spacing(1),
            fontSize: 80,
        },
    },
}));

const StyledTitle = styled(Box)(({theme}) => ({
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(6),
    [theme.breakpoints.down('sm')]: {
        display: 'none'
    }
}));

const StyledText = styled(Typography)(({theme}) => ({
    [theme.breakpoints.up('sm')]: {
        marginBottom: theme.spacing(4),
    }
}));

const StyledGrid = styled(Grid)(({theme}) => ({
    [theme.breakpoints.down('sm')]: {
        flexDirection: 'column-reverse',
    }
}));

const StyledCardContent = styled(CardContent)(({theme}) => ({
    [theme.breakpoints.down('sm')]: {
        display: 'flex',
        alignItems: 'center'
    }
}));

function Chooser() {

    const context = useContext(AppContext);

    return (
        <Box color="background.paper" textAlign="center" sx={{
            backgroundColor: theme => theme.palette.grey['200'],
            minHeight: '100vh',
            background: `url(${BackgroundImage}) no-repeat center / cover`,
            paddingBottom: 5,
        }}>

            <Header
                showOverviewButton={false}
                showPinboardButton={false}
                showDownloadButton={false}
            />

            <Container maxWidth="md" sx={{paddingTop: 8}}>
                <StyledTitle>
                    <Typography variant="h3">Auswahl</Typography>
                    <Typography variant="subtitle1">Wie sieht Ihr weiteres Vorgehen aus?</Typography>
                </StyledTitle>
                <StyledGrid container spacing={5}>
                    {(context.ftpUploadUrl && context.ftpUploadUrl !== '') &&
                        <Grid item xs>
                            <Card>
                                <CardActionArea href={context.ftpUploadUrl} target="_blank">
                                    <StyledCardContent>
                                        <StyledAvatar alt="Upload">
                                            <Backup/>
                                        </StyledAvatar>
                                        <Box color="primary.main">
                                            <StyledText variant="h4">
                                                Upload
                                            </StyledText>
                                        </Box>
                                    </StyledCardContent>
                                </CardActionArea>
                            </Card>
                        </Grid>
                    }
                    <Grid item xs>
                        <Card>
                            <CardActionArea href="/gallery">
                                <StyledCardContent>
                                    <StyledAvatar alt="Upload">
                                        <ImageSearch/>
                                    </StyledAvatar>
                                    <Box color="primary.main">
                                        <StyledText variant="h4">
                                            Galeria Gallery
                                        </StyledText>
                                    </Box>
                                </StyledCardContent>
                            </CardActionArea>
                        </Card>
                    </Grid>
                </StyledGrid>
                <Box sx={{marginTop: 4}}>
                    <Typography variant="h5" gutterBottom>
                        Look how beautiful we are
                    </Typography>
                </Box>
            </Container>
        </Box>
    );
}

export default Chooser;
