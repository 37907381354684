import {Button, Dialog, DialogActions, DialogContent, DialogTitle, useMediaQuery, useTheme} from '@mui/material';
import {DialogProps} from '@mui/material/Dialog/Dialog';
import {useContext, useEffect, useState} from 'react';
import AppContext from '../../../AppContext';
import {downloadOptionTypeClient, fileClient} from '../../../clients/clients';
import DownloadFileRow from '../../../components/common/DownloadFileRow';
import {MPObject} from '../../../types/mediaport/mpobject';
import {getAxiosInstance} from '../../../utils/axios';
import {getAttributeValue} from '../../../utils/MediaPortUtils';
import {toNumber} from '../../../utils/NumberUtils';
import {getLocalizableString} from '../../../utils/ResourceUtils';

interface Props extends DialogProps {
    fileId: string;
    onCancel: () => void;
}

interface DownloadOptionType {
    id: number,
    name: string,
    translation: string
}

function SingleFileDownloadDialog(props: Props) {

    const context = useContext(AppContext);

    const [file, setFile] = useState<MPObject>();
    const [allDownloadOptionTypes, setAllDownloadOptionTypes] = useState<Map<number, DownloadOptionType>>();
    const [fileDownloadOptionTypes, setFileDownloadOptionTypes] = useState<DownloadOptionType[]>();
    const [selectedDownloadOptionType, setSelectedDownloadOptionType] = useState<number>();

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const {fileId, open, onCancel, ...dialogProps} = props;

    // Get all DownloadOptionTypes once
    useEffect(() => {
        console.debug('Get all DownloadOptionTypes.');
        downloadOptionTypeClient
            .getDownloadOptionTypes()
            .then(downloadOptionTypes => {
                setAllDownloadOptionTypes(
                    new Map(
                        downloadOptionTypes
                            .map((dot) => {
                                return {
                                    id: dot.id,
                                    name: dot.name,
                                    translation: getLocalizableString(dot, 'name', 'de'),
                                } as DownloadOptionType;
                            })
                            .filter(dot => dot.name !== 'user-defined')
                            .filter(dot => dot.translation)
                            .map(dot => [dot.id, dot] as [number, DownloadOptionType])
                    )
                );
            })
            .catch(e => {
                console.error('Failed to get all DownloadOptionTypes.', e);
            });
    }, []);

    // Get file
    useEffect(() => {

        if (fileId === undefined) {
            setFile(undefined);
            setFileDownloadOptionTypes(undefined);
            return;
        }

        console.debug('Get File.');

        fileClient
            .searchByQuery(
                {
                    'search': {
                        'query': {
                            'id': {
                                'isIn': [toNumber(fileId)]
                            }
                        },
                    },
                    'properties': {
                        'attributes': ['file.name', 'file.widthPixel', 'file.heightPixel']
                    }
                },
                {'params': {'page': 0, 'size': 100}}
            )
            .then(result => {
                if (result.content.length > 0) {
                    setFile(result.content[0]);
                }
                else {
                    setFile(undefined);
                }
            })
            .catch(e => {
                console.error('Failed to get File.', e);
            });
    }, [fileId]);

    // Get DownloadOptionTypes for File (after file and downloadOptionTypes are loaded)
    useEffect(() => {

        if (fileId === undefined || file === undefined || allDownloadOptionTypes === undefined) {
            return;
        }

        console.debug('Get DownloadOptionTypes for File.');

        downloadOptionTypeClient.getDownloadOptionTypesForFiles([file.id])
            .then(result => {
                const downloadOptionTypes = result[0]
                    .downloadOptionTypeIds
                    .map(id => allDownloadOptionTypes.get(id))
                    .filter(dot => dot) as DownloadOptionType[];

                setFileDownloadOptionTypes(downloadOptionTypes);

                setSelectedDownloadOptionType(downloadOptionTypes[0].id);
            })
            .catch(e => {
                console.error('Failed to get DownloadOptionTypes for file.', e);
            });

    }, [fileId, file, allDownloadOptionTypes]);

    const startDownload = () => {

        getAxiosInstance()
            .post(
                '/api/fileDownloads/create',
                {
                    name: getAttributeValue(file, 'file.name'),
                    comment: 'Galeria Karstadt Kaufhof Download',
                    validityPeriod: 30,
                    files: [{
                        id: toNumber(fileId),
                        downloadOptionTypeId: selectedDownloadOptionType
                    }]
                }
            )
            .then(result => {
                onCancel();
                context.updateDownloads();
                context.showSnackbar('Ihr Download wird vorbereitet.');
                console.log(result);
            })
            .catch(e => {
                console.error('Failed to start download.', e);
            });
    };

    if (file === undefined) {
        return null;
    }

    return (
        <>
            <Dialog {...dialogProps} open={open} onClose={() => onCancel()} fullScreen={fullScreen}>
                <DialogTitle id="form-dialog-title" sx={{pl: 2}}>Datei herunterladen</DialogTitle>
                <DialogContent sx={{pl: 0, pr: 0}}>
                    <DownloadFileRow mpObject={file}
                                     fileDownloadOptionTypes={fileDownloadOptionTypes}
                                     selectedDownloadOptionType={selectedDownloadOptionType}
                                     setSelectedDownloadOptionType={setSelectedDownloadOptionType}
                                     active={true}/>
                </DialogContent>
                <DialogActions sx={{flexWrap: 'wrap'}}>

                    <Button onClick={() => onCancel()}>
                        Schließen
                    </Button>

                    {file && (

                        <Button onClick={() => startDownload()} color="primary" variant="contained" disabled={!fileDownloadOptionTypes || fileDownloadOptionTypes?.length <= 0}>
                            Download starten
                        </Button>
                    )}

                </DialogActions>
            </Dialog>
        </>
    );
}

export default SingleFileDownloadDialog;
