import {Box} from '@mui/material';
import {useContext, useEffect, useState} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import AppContext from '../AppContext';
import {getDateCondition, getEnumerationCondition, getFilterCondition, getLicenseWorkflowCondition, getResolutionCondition, getSortAttribute} from '../utils/SearchUtils';
import Header from './common/Header';
import FilterBar from './gallery/FilterBar';
import ImageResult from './gallery/ImageResult';
import Pinboard from './gallery/pinboard/Pinboard';
import SideBar, {SidebarFilters} from './gallery/sidebar/SideBar';

interface SearchParams {
    sortType: string,
    activeFilter: string,
    sidebarFilters: SidebarFilters,
}

function Gallery() {

    const context = useContext(AppContext);
    const history = useHistory();
    const location = useLocation();

    const urlSearchParams = new URLSearchParams(location.search);

    const [sidebarExpanded, setSidebarExpanded] = useState(localStorage.getItem('sidebarExpanded') === `${true}`);
    const [pinboardExpanded, setPinboardExpanded] = useState(false);

    const [searchParams, setSearchParams] = useState<SearchParams>({
        activeFilter: urlSearchParams.get('filter') || '_all_',
        sortType: urlSearchParams.get('sortType') || 'newest',
        sidebarFilters: {
            resolution: urlSearchParams.get('resolution')?.split(',') ?? [],
            color: urlSearchParams.get('color')?.split(',') ?? [],
            signet: urlSearchParams.get('signet')?.split(',') ?? [],
            orientation: urlSearchParams.get('orientation')?.split(',') ?? [],
            licenceType: urlSearchParams.get('licenceType')?.split(',') ?? [],
            amountOfPersons: urlSearchParams.get('amountOfPersons')?.split(',') ?? [],
            ageGroup: urlSearchParams.get('ageGroup')?.split(',') ?? [],
            personComposition: urlSearchParams.get('personComposition')?.split(',') ?? [],
            imageStyle: urlSearchParams.get('imageStyle')?.split(',') ?? [],
            imageMood: urlSearchParams.get('imageMood')?.split(',') ?? [],
            storeSituation: urlSearchParams.get('storeSituation')?.split(',') ?? [],
            season: urlSearchParams.get('season')?.split(',') ?? [],
            goodsCategory: urlSearchParams.get('goodsCategory')?.split(',') ?? [],
            emotion: urlSearchParams.get('emotion')?.split(',') ?? [],
            imageComposition: urlSearchParams.get('imageComposition')?.split(',') ?? [],
            dateFrom: urlSearchParams.get('dateFrom')?.split(',') ?? [],
            dateTo: urlSearchParams.get('dateTo')?.split(',') ?? [],
            license: urlSearchParams.get('license')?.split(',') ?? [],
            shooting: urlSearchParams.get('shooting')?.split(',') ?? [],
            agency: urlSearchParams.get('agency')?.split(',') ?? [],
            usageRight: urlSearchParams.get('usageRights')?.split(',') ?? [],
        }
    });

    const [searchJson, setSearchJson] = useState<any | undefined>();

    useEffect(() => {
        setSearchJson(buildSearchJson(searchParams));
    }, [searchParams]);

    useEffect(() => {
        context.updateCollections();
    }, []); // eslint-disable-line

    const toggleSidebar = () => {
        const newState = !sidebarExpanded;
        setSidebarExpanded(newState);
        localStorage.setItem('sidebarExpanded', `${newState}`);
    };

    const togglePinboard = () => {
        setPinboardExpanded(!pinboardExpanded);
    };

    const onActiveFilterChange = (activeFilter: string) => {
        updateUrl('filter', activeFilter);
        setSearchParams({
            ...searchParams,
            activeFilter: activeFilter
        });
    };

    const onSortTypeChange = (sortType: string) => {
        const urlSearchParams = new URLSearchParams(location.search);
        urlSearchParams.set('sortType', sortType);

        history.push({
            pathname: '/gallery',
            search: urlSearchParams.toString()
        });

        setSearchParams({
            ...searchParams,
            sortType: sortType
        });
    };

    const onSidebarFiltersChange = (sidebarFilters: SidebarFilters) => {

        const filters = [
            'resolution',
            'color',
            'signet',
            'orientation',
            'licenceType',
            'amountOfPersons',
            'ageGroup',
            'personComposition',
            'imageStyle',
            'imageMood',
            'storeSituation',
            'season',
            'goodsCategory',
            'emotion',
            'imageComposition',
            'dateFrom',
            'dateTo',
            'license',
            'shooting',
            'agency',
            'usageRights',
        ];

        const urlSearchParams = new URLSearchParams(location.search);

        for (const filter of filters) {

            const selectedItems: string[] = sidebarFilters[filter];

            if (selectedItems && selectedItems.length > 0) {
                urlSearchParams.set(filter, selectedItems.join(','));
            }
            else {
                urlSearchParams.delete(filter);
            }
        }

        history.push({
            pathname: '/gallery',
            search: urlSearchParams.toString()
        });

        setSearchParams({
            ...searchParams,
            sidebarFilters: sidebarFilters
        });
    };

    const updateUrl = (key: string, value: string | null) => {

        const urlSearchParams = new URLSearchParams(location.search);

        if (value == null) {
            urlSearchParams.delete(key);
        }
        else {
            urlSearchParams.set(key, value);
        }

        history.push({
            pathname: '/gallery',
            search: urlSearchParams.toString()
        });
    };

    const buildSearchJson = (searchParams: SearchParams) => {

        const conditions = [
            getFilterCondition(searchParams.activeFilter),
            getResolutionCondition(searchParams.sidebarFilters['resolution']?.[0]),
            getDateCondition('file.objectCreated', 'afterOrEqual', searchParams.sidebarFilters['dateFrom']?.[0]),
            getDateCondition('file.objectCreated', 'beforeOrEqual', searchParams.sidebarFilters['dateTo']?.[0]),
            getEnumerationCondition('file.colors', searchParams.sidebarFilters['color']),
            getEnumerationCondition('file.signet', searchParams.sidebarFilters['signet']),
            getEnumerationCondition('file.orientation', searchParams.sidebarFilters['orientation']),
            getEnumerationCondition('file.licenceType', searchParams.sidebarFilters['licenceType']),
            getEnumerationCondition('file.amountOfPersons', searchParams.sidebarFilters['amountOfPersons']),
            getEnumerationCondition('file.ageGroup', searchParams.sidebarFilters['ageGroup']),
            getEnumerationCondition('file.personComposition', searchParams.sidebarFilters['personComposition']),
            getEnumerationCondition('file.imageStyle', searchParams.sidebarFilters['imageStyle']),
            getEnumerationCondition('file.imageMood', searchParams.sidebarFilters['imageMood']),
            getEnumerationCondition('file.storeSituation', searchParams.sidebarFilters['storeSituation']),
            getEnumerationCondition('file.season', searchParams.sidebarFilters['season']),
            getEnumerationCondition('file.goodsCategory', searchParams.sidebarFilters['goodsCategory']),
            getEnumerationCondition('file.emotions', searchParams.sidebarFilters['emotion']),
            getEnumerationCondition('file.imageComposition', searchParams.sidebarFilters['imageComposition']),
            getEnumerationCondition('file.shooting', searchParams.sidebarFilters['shooting']),
            getEnumerationCondition('file.agency', searchParams.sidebarFilters['agency']),
            getEnumerationCondition('file.usageRights', searchParams.sidebarFilters['usageRights']),
            getLicenseWorkflowCondition(searchParams.sidebarFilters['license']),
        ].filter(c => c);

        return {
            'search': {
                'query': {
                    'all': conditions
                },
                'sorts': [
                    {
                        'attribute': {
                            'key': getSortAttribute(searchParams.sortType)
                        },
                        'direction': 'desc'
                    }
                ]
            },
            'properties': {
                'attributes': [
                    'file.name',
                    'file.widthPixel',
                    'file.heightPixel'
                ],
                'workflows': [
                    'file.licenseWorkflow'
                ]
            }
        };
    };

    return (
        <>
            <Pinboard open={pinboardExpanded} onClose={() => setPinboardExpanded(false)}/>
            <Header
                showOverviewButton={true}
                showPinboardButton={true}
                showDownloadButton={true}
                onTogglePinboard={togglePinboard}
            />

            <Box width="100%">
                <FilterBar
                    activeFilter={searchParams.activeFilter}
                    onActiveFilterChange={onActiveFilterChange}
                    toggleSidebar={toggleSidebar}
                />
            </Box>

            <Box display="flex" flexDirection="row" flexGrow={1} sx={{
                backgroundColor: theme => theme.palette.grey['200'],
                overflowX: 'hidden',
            }}>

                <SideBar
                    sortType={searchParams.sortType}
                    sidebarFilters={searchParams.sidebarFilters}
                    sidebarExpanded={sidebarExpanded}
                    onSortTypeChanged={onSortTypeChange}
                    onSidebarFiltersChange={onSidebarFiltersChange}
                />

                <Box flexGrow={1}>
                    <ImageResult search={searchJson}/>
                </Box>
            </Box>

        </>
    );
}

export default Gallery;
