import * as AxiosLaudertAuth from '@laudert/axios-laudert-auth';
import axios, {AxiosInstance} from 'axios';

const request = axios.create();
var requestInterceptorId: number | undefined = undefined;

export function getAxiosInstance(): AxiosInstance {
    return request;
}

export function setAxiosSpecs(apiBaseUrl?: string, apiKey?: string, secret?: string) {

    if (apiBaseUrl) {

        request.defaults.baseURL = apiBaseUrl;

        if (requestInterceptorId !== undefined) {
            request.interceptors.request.eject(requestInterceptorId);
        }

        if (apiKey && secret) {
            requestInterceptorId = request.interceptors.request.use(new AxiosLaudertAuth(apiKey, secret, false).interceptor);
        }
    }
}