import {AxiosInstance} from 'axios';
import {DownloadOptionTypeResource, FileDownloadOptionTypesResource} from '../types/mediaport/download';

export class DownloadOptionTypeClient {

    protected readonly axiosInstance: AxiosInstance;

    constructor(axiosInstance: AxiosInstance) {
        this.axiosInstance = axiosInstance;
    }

    public getDownloadOptionTypes(): Promise<DownloadOptionTypeResource[]> {
        return this.axiosInstance
            .get(
                '/api/downloadOptionTypes',
                {'params': {'page': 0, 'size': 100}}
            )
            .then(result => {
                return result.data.content as DownloadOptionTypeResource[];
            });
    }

    public getDownloadOptionTypesForFiles(fileIds: string[]): Promise<FileDownloadOptionTypesResource[]> {
        return this.axiosInstance
            .post(
                '/api/downloadOptionTypes/getForFileIds',
                fileIds,
                {'params': {'page': 0, 'size': 100}}
            )
            .then(result => {
                return result.data.list as FileDownloadOptionTypesResource[];
            });
    }
}
