import {AxiosInstance} from 'axios';
import {AuthenticationResult} from '../types/mediaport/auth';

export class AuthenticationClient {

    protected readonly axiosInstance: AxiosInstance;

    constructor(axiosInstance: AxiosInstance) {
        this.axiosInstance = axiosInstance;
    }

    public authenticate(username: string, password: string): Promise<AuthenticationResult> {
        return this.axiosInstance
            .post(
                '/api/users/authenticate',
                {
                    'userName': username,
                    'password': password,
                    'retrieveRestCredentials': true
                }
            )
            .then(response => {
                return response.data as AuthenticationResult;
            });
    }
}
