import {Search} from '@mui/icons-material';
import {Box, Button} from '@mui/material';
import {grey} from '@mui/material/colors';
import React, {useState} from 'react';
import SingleFileDownloadDialog from '../gallery/download/SingleFileDownloadDialog';
import MPCollectionSplitButton from './MPCollectionSplitButton';
import MPDownloadButton from './MPDownloadButton';
import MPImageDialog from './MPImageDialog';

interface Props {
    fileId: string;
    previewId: string | null;
    fileName?: string;
    fileWidthPixel?: string;
    fileHeightPixel?: string;
    showDownloadButton: boolean;
}

function MPImageOverlay(props: Props) {

    const [dialogOpen, setDialogOpen] = useState(false);
    const [downloadDialogOpen, setDownloadDialogOpen] = useState(false);
    const {fileId, fileName, previewId} = props;

    return (
        <>
            {dialogOpen && (<MPImageDialog open={dialogOpen} fileId={fileId} fileName={fileName} previewId={previewId} onClose={() => setDialogOpen(false)}/>)}
            {downloadDialogOpen && (<SingleFileDownloadDialog open={downloadDialogOpen} fileId={fileId} onCancel={() => setDownloadDialogOpen(false)}/>)}
            <Box display="flex" flexDirection="column" flexGrow={1} sx={{
                position: 'absolute',
                top: 0.5,
                left: 0.5,
                bottom: 0.5,
                right: 0.5,
                opacity: 0,
                display: 'flex',
                flexGrow: 1,
                '&:hover': {
                    opacity: 1,
                    backgroundColor: 'rgba(0,0,0,0.3)',
                }
            }} onDoubleClick={() => setDialogOpen(true)}>
                <Box sx={{padding: 0.5}}>
                    <Box sx={{
                        borderColor: grey[800],
                        borderRadius: '4px',
                        width: '100%',
                        opacity: 0.9,
                        padding: 1,
                        fontWeight: 'bold',
                        borderStyle: 'solid',
                        borderWidth: 'thin',
                        color: 'white',
                        backgroundColor: 'rgba(0,0,0,0.7)',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                    }}>
                        {fileName}
                    </Box>
                </Box>
                <Box display="flex" flexGrow={1} alignItems={'flex-end'} sx={{padding: 0.5}}>
                    <Box display="flex" flexGrow={1} justifyContent={'flex-start'}>
                        <Button variant={'outlined'} size={'small'} sx={{
                            borderColor: grey[800],
                            borderWidth: 'thin',
                            color: 'white',
                            backgroundColor: 'rgba(0,0,0,0.7)',
                            minHeight: 0,
                            minWidth: 0,
                            textTransform: 'none',
                            transitionDuration: '0ms',
                            '&:hover': {
                                transitionDuration: '0ms',
                                opacity: 1,
                                color: 'black',
                                borderColor: 'black',
                                backgroundColor: 'white',
                            }
                        }} onClick={() => setDialogOpen(true)}>
                            <Search/>
                        </Button>
                    </Box>
                    <Box display="flex" flexGrow={1} justifyContent={'flex-end'}>
                        {props.showDownloadButton && <MPDownloadButton onClick={() => setDownloadDialogOpen(true)}/>}
                        <MPCollectionSplitButton fileId={fileId}/>
                    </Box>
                </Box>
            </Box>
        </>
    );
}

export default MPImageOverlay;
