import axios from 'axios';
import {useEffect, useState} from 'react';
import {previewClient} from '../clients/clients';

/**
 * Returns:
 * * loading     - if image is still loading
 * * available   - if previewId is defined and not null
 * * imageSource - the fetched image data
 * * error       - a flag that tells if an error occured
 */
export default function useLoadPreview(previewId: string | null | undefined) {

    const [loading, setLoading] = useState(true);
    const [available, setAvailable] = useState(false);
    const [imageSource, setImageSource] = useState<string>();
    const [error, setError] = useState(false);

    useEffect(() => {
        setLoading(true);
        setAvailable(previewId !== undefined && previewId !== null);
        setImageSource(undefined);
        setError(false);
    }, [previewId]);

    useEffect(() => {

        if (previewId === undefined) {
            return;
        }
        else if (previewId === null) {
            setLoading(false);
            return;
        }

        let cancel;
        previewClient
            .getBase64EncodedPreview(previewId, {
                cancelToken: new axios.CancelToken(c => cancel = c)
            })
            .then(imageData => {
                const base64 = imageData.content;
                setImageSource(`data:${imageData.contentType};base64,${base64}`);
                setLoading(false);
            })
            .catch(e => {
                if (axios.isCancel(e)) {
                    console.debug('Request canceled');
                }
                else {
                    console.error('Error', e.message);
                    setError(true);
                }
            });
        return () => cancel();
    }, [previewId]);

    return {imageSource, loading, available, error};
}
