export function getFilterCondition(filter: string) {
    if (filter === '_all_') {
        return {
            'all': [
                {
                    'objectClass': {
                        'isRecursive': 'file.gallery'
                    }
                }
            ]
        };
    }

    return {
        'all': [
            {
                'objectClass': {
                    'isRecursive': 'file.gallery'
                }
            },
            {
                'attribute': {
                    'key': 'file.classificationFilter',
                    'is': filter
                }
            }
        ]
    };
}

export function getResolutionCondition(resolution: string) {
    if (resolution) {
        return {
            'attribute': {
                'key': 'file.imageResolution',
                'greaterThanOrEqual': (Number(resolution.toString().replace(' MP', '')) * 1000 * 1000)
            }
        };
    }
    return null;
}

export function getEnumerationCondition(attributeName: string, selectedValues: string[]) {
    if (selectedValues && selectedValues.length > 0) {
        return {
            'any': selectedValues.map(value => {
                return {
                    'attribute': {
                        'key': attributeName,
                        'is': value
                    }
                };
            })
        };
    }
    return null;
}

export function getDateCondition(attributeName: string, option: string, dateValue: string) {
    if (dateValue) {
        return {
            'attribute': {
                'key': attributeName,
                [option]: dateValue,
                'truncate': 'day'
            }
        };
    }
    return null;
}

export function getLicenseWorkflowCondition(selectedValues: string[]) {
    if (selectedValues && selectedValues.length > 0) {

        let states: string[] = [];
        if (selectedValues.includes('validLicense')) {
            states = ['validRestrictedLicense', 'validRestrictedLicenseAndRenewalRequested', 'validUnlimitedLicense'];
        }
        else if (selectedValues.includes('invalidLicense')) {
            states = ['toCheck', 'renewalRequested', 'invalidLicense', 'licenseRenewalNotNeeded'];
        }

        return {
            'workflowState': {
                'name': 'file.licenseWorkflow',
                'any': states
            }
        };
    }

    return null;
}

export function getSortAttribute(sortType: string) {
    switch (sortType) {
        case 'mostPopular':
            return 'file.countDownloads';
        case 'newest':
        default:
            return 'file.objectCreated';
    }
}
