import axios from 'axios';
import * as React from 'react';
import {useEffect, useState} from 'react';
import {fileClient} from '../clients/clients';
import {MPObject} from '../types/mediaport/mpobject';

export default function useSearchMPObjects(controller: string, search: any, pageSize: number) {

    const [loading, setLoading] = useState(true);
    const [pageNumber, setPageNumber] = useState(0);
    const [error, setError] = useState(false);
    const [items, setItems] = React.useState<MPObject[]>([]);
    const [hasMoreItems, setHasMoreItems] = useState(false);
    const [totalItemCount, setTotalItemCount] = useState<number>();

    useEffect(() => {
        setItems([]);
        setTotalItemCount(undefined);
        setPageNumber(0);
    }, [controller, search, pageSize]);

    useEffect(() => {

        setError(false);
        setLoading(true);

        if (!search) {
            return;
        }

        let cancel;

        fileClient
            .searchByQuery(
                search,
                {
                    params: {
                        page: pageNumber,
                        size: pageSize
                    },
                    cancelToken: new axios.CancelToken(c => cancel = c)
                }
            )
            .then(searchResult => {
                setItems((current) => [...current, ...searchResult.content]);
                setHasMoreItems(searchResult.page.totalPages > (pageNumber + 1));
                setTotalItemCount(searchResult.page.totalElements);
                setLoading(false);
            })
            .catch(e => {
                if (axios.isCancel(e)) {
                    return;
                }
                setError(true);
            });
        return () => cancel();
    }, [controller, search, pageSize, pageNumber]);

    const loadMore = () => {
        setPageNumber(pageNumber + 1);
    };

    return {items, totalItemCount, pageNumber, loading, hasMoreItems, loadMore, error};
}
