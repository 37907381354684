import {getAxiosInstance} from '../utils/axios';
import {AuthenticationClient} from './AuthenticationClient';
import {CollectionClient} from './CollectionClient';
import {DownloadClient} from './DownloadClient';
import {DownloadOptionTypeClient} from './DownloadOptionTypeClient';
import {EnumerationClient} from './EnumerationClient';
import {GalleryClient} from './GalleryClient';
import {MPObjectClient} from './MPObjectClient';
import {PreviewClient} from './PreviewClient';

export const authenticationClient = new AuthenticationClient(getAxiosInstance());
export const fileClient = new MPObjectClient('files', getAxiosInstance());
export const downloadClient = new DownloadClient(getAxiosInstance());
export const previewClient = new PreviewClient(getAxiosInstance());
export const enumerationClient = new EnumerationClient(getAxiosInstance());
export const collectionClient = new CollectionClient(getAxiosInstance());
export const galleryClient = new GalleryClient(getAxiosInstance());
export const downloadOptionTypeClient = new DownloadOptionTypeClient(getAxiosInstance());
