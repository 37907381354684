import {Color, createTheme} from '@mui/material';
import {grey, lightBlue, lightGreen, orange, red} from '@mui/material/colors';

const themeColorShade: keyof Color = 700;
const themeColorShadeLight: keyof Color = 500;
const themeColorShadeDark: keyof Color = 900;

export interface CustomThemeSettings {
    mainColor: string;
}

export const customThemeSettings: CustomThemeSettings = {
    mainColor: '#455262'
};

const theme = createTheme({

    typography: {
        button: {
            textTransform: 'none'
        }
    },

    palette: {
        primary: {
            main: '#415364',
        },
        secondary: {
            main: red[themeColorShade],
        },
        success: {
            main: lightGreen[themeColorShade],
            light: lightGreen[themeColorShadeLight],
            dark: lightGreen[themeColorShadeDark]
        },
        info: {
            main: lightBlue[themeColorShade],
            light: lightBlue[themeColorShadeLight],
            dark: lightBlue[themeColorShadeDark]
        },
        warning: {
            main: orange[themeColorShade],
            light: orange[themeColorShadeLight],
            dark: orange[themeColorShadeDark]
        },
        error: {
            main: red[themeColorShade],
            light: red[themeColorShadeLight],
            dark: red[themeColorShadeDark]
        }
    },

    components: {

        MuiChip: {
            styleOverrides: {
                outlined: {
                    borderColor: 'grey',
                    borderRadius: '4px',
                    backgroundColor: grey['200'],
                }
            },
        },
        MuiDialog: {
            defaultProps: {
                fullWidth: true,
            }
        },
        MuiPaper: {
            defaultProps: {
                square: true,
                elevation: 0,
            }
        },
    },
});

export {
    theme
};
