import {useState} from 'react';
import {collectionClient} from '../clients/clients';
import {CollectionResource} from '../types/mediaport/collection';

export default function useCollections() {

    const [collections, setCollections] = useState<CollectionResource[]>();

    const [defaultCollectionId, setDefaultCollectionId] = useState<string>(localStorage.getItem('defaultCollectionId')!);

    const updateCollections = () => {
        collectionClient
            .getCollections()
            .then(response => {
                setCollections(response.data['content'] as CollectionResource[]);
            })
            .catch(e => {
                setCollections([]);
                console.error('Failed to load collections.', e);
            });
    };

    const updateDefaultCollectionId = (collectionId: string) => {
        setDefaultCollectionId(collectionId);
        localStorage.setItem('defaultCollectionId', `${collectionId}`);
    };

    return {
        collections, updateCollections,
        defaultCollectionId, updateDefaultCollectionId
    };
}