import {Chip} from '@mui/material';
import React, {MouseEventHandler, useEffect, useState} from 'react';
import {enumerationClient} from '../../../clients/clients';
import {getLocalizableString} from '../../../utils/ResourceUtils';

interface Props {
    filterKey: string;
    filterValue: string;
    onDelete: React.EventHandler<any>;
    onClick: MouseEventHandler<HTMLDivElement>;
}

const filterToEnumeration = {
    'color': 'color',
    'signet': 'file.signet',
    'orientation': 'file.orientation',
    'licenceType': 'file.licenceType',
    'amountOfPersons': 'file.amountOfPersons',
    'ageGroup': 'file.ageGroup',
    'personComposition': 'file.personComposition',
    'imageStyle': 'file.imageStyle',
    'imageMood': 'file.imageMood',
    'storeSituation': 'file.storeSituation',
    'season': 'file.season',
    'goodsCategory': 'file.goodsCategory',
    'emotion': 'file.emotions',
    'imageComposition': 'file.imageComposition'
};

export default function FilterChip(props: Props) {

    const {filterKey, filterValue, onClick, onDelete} = props;

    const [label, setLabel] = useState<string>();

    useEffect(() => {

        if (Object.keys(filterToEnumeration).includes(filterKey)) {

            enumerationClient
                .searchValue(filterToEnumeration[filterKey], filterValue)
                .then(enumerationValue => {
                    setLabel(getLocalizableString(enumerationValue, 'name', 'de'));
                });
        }
        else if (filterKey === 'resolution') {
            setLabel(filterValue + ' und mehr');
        }
        else {
            setLabel(filterValue);
        }

    }, [filterKey, filterValue]);

    return <Chip
        label={label}
        onClick={onClick}
        onDelete={onDelete}
    />;
}
