import {Clear} from '@mui/icons-material';
import {Grid, IconButton, TextField} from '@mui/material';
import {useEffect, useState} from 'react';

interface Props {
    label: string,
    dateString: string;
    onChangeDateString: (dateString: string) => void;
}

export default function NativeDatePicker(props: Props) {

    const [tmpDateString, setTmpDateString] = useState(props.dateString);

    useEffect(() => {
        setTmpDateString(props.dateString);
    }, [props.dateString]);

    return (
        <Grid style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%',}}>
            <TextField
                id="date"
                size={'small'}
                label={props.label}
                type="date"
                value={tmpDateString}
                variant={'outlined'}
                style={{width: '100%'}}
                InputLabelProps={{
                    shrink: true,
                }}
                onChange={e => setTmpDateString(e.target.value)}
                onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                        e.preventDefault();
                        props.onChangeDateString(tmpDateString);
                    }
                }}
            />
            <IconButton size={'small'}>
                <Clear
                    fontSize={'small'}
                    onClick={() => {
                        setTmpDateString('');
                        if (props.dateString !== '') {
                            props.onChangeDateString('');
                        }
                    }}
                />
            </IconButton>
        </Grid>
    );
}
