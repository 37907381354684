import {useEffect, useState} from 'react';
import {galleryClient} from '../clients/clients';

export default function useFtpUploadUrl() {

    const [ftpUploadUrl, setFtpUploadUrl] = useState<string>();

    useEffect(() => {
        galleryClient
            .getFtpUploadUrl()
            .then(ftpUploadUrl => {
                setFtpUploadUrl(ftpUploadUrl);
            })
            .catch(e => {
                setFtpUploadUrl('');
                console.error('Failed to fetch ftpUploadUrl.', e);
            });
    }, []);

    return ftpUploadUrl;
}